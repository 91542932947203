import { Grid, Form, Button, Dropdown, Icon, Checkbox, Input } from 'semantic-ui-react'
import { useState, useEffect } from "react";
import PageHeader from "../Components/pageHeader";
import { Errors } from '../Constants/errors';
import { userRegisterRequest, userDataRequest, usersDataRequest, updateUserRequest, networksDataRequest, contractTypesDataRequest } from '../api/backend';
import { useParams } from 'react-router-dom';
import { displayErrors, displaySuccess } from '../Components/displayNotifications';

export default function EditUsers(properties) {

    const {
        title = "User",
        isAdmin,
    } = properties;

    const [changePassword, setChangePassword] = useState(false);
    const [optionsNetworkAffiliation, setOptionsNetworkAffiliation] = useState([]);
    const [optionsContractExpirationTypes, setOptionsContractExpirationTypes] = useState([]);
    const [formData, setFormData] = useState({})
    const [invalid, setInvalid] = useState(false);
    const [loading, setLoading] = useState(false);
    const { userId } = useParams();

    const signUp = !userId;

    // ================================================= Retrieve data required for the filter options
    useEffect(() => {

        // Only admins can filter
        if (!isAdmin) return;

        const fetchData = async () => {

            setLoading(true);

            const requestNetworks = await networksDataRequest();
            const requestContractTypes = await contractTypesDataRequest();

            if ([200, 201, 204].includes(requestNetworks.status)) {
                const networks = await requestNetworks.json();
                setOptionsNetworkAffiliation(
                    networks.map((network, index) => {
                        return {
                            key: index,
                            text: network.name,
                            value: network.id
                        }
                    })
                )

            }

            if ([200, 201, 204].includes(requestContractTypes.status)) {
                const contractTypes = await requestContractTypes.json();
                setOptionsContractExpirationTypes(
                    contractTypes.map((contract, index) => {
                        return {
                            key: index,
                            text: contract.label,
                            value: contract.id
                        }
                    })
                )

            }

            setLoading(false);
        }

        fetchData();

    }, [])

    // ================================================= Retrieve user data for editing
    useEffect(() => {

        // Only when editing an existing affiliation
        if (!userId) return;

        const fetchData = async () => {

            setLoading(true);

            let request

            if (isAdmin) {
                request = await usersDataRequest({

                    limit: 1,

                    filter: {
                        id: userId
                    }

                })
            } else {
                request = await userDataRequest()
            }

            if ([200, 201, 204].includes(request.status)) {
                const userData = await request.json()
                setFormData(isAdmin ? userData[0] : userData);

                setLoading(false);
            }

            setLoading(false);
        }

        fetchData();
    }, [])

    const changeFormData = (e) => {

        setFormData({
            ...formData, [`${e.target.name}`]: e.target.value
        })

    }

    const handleSubmitUserForm = async (e) => {

        e.preventDefault();
        setLoading(true);

        if (!e.target.checkValidity()) {
            setInvalid(true);
            setLoading(false);
            return null;
        }

        let request;

        if (isAdmin) {
            request = await updateUserRequest(formData, userId);
        } else if (userId) {
            request = await updateUserRequest(formData);
        } else {
            request = await userRegisterRequest(formData);
        }

        if ([200, 201, 204].includes(request.status)) {
            if (userId) {
                displaySuccess("User edited succesfully");
            } else {
                displaySuccess("User created succesfully, check you e-mail");
            }
            setLoading(false);
        } else {
            const response = await request.json();
            displayErrors(response);
        }

        setLoading(false);

    }

    return (
        <>
            <Form
                onSubmit={handleSubmitUserForm}
                noValidate
            >
                <div className="ui container" style={{ marginTop: '2em' }}>
                    <PageHeader
                        title={title}
                        icon={"user"}
                    />
                    <Grid style={{ marginTop: '2em' }}>
                        <Grid.Row columns={2} stretched>
                            <Grid.Column width={10}>
                                <Form.Field
                                    icon="mail"
                                    name="emailAddress"
                                    value={formData.emailAddress || ''}
                                    onChange={e => changeFormData(e)}
                                    control={Input}
                                    iconPosition={'left'}
                                    label={"E-mail address"}
                                    required
                                    error={invalid && !formData?.emailAddress ? {
                                        content: Errors.email,
                                        pointing: 'below',
                                    } : false}
                                />
                            </Grid.Column>

                            {isAdmin || signUp &&
                                <Grid.Column width={6}>
                                    <Form.Field>
                                        {changePassword || signUp
                                            ? <Form.Field
                                                icon="lock"
                                                iconPosition='left'
                                                placeholder="enter password"
                                                name="password"
                                                label="Password"
                                                control={Input}
                                                type="password"
                                                value={formData.password || ''}
                                                onChange={e => changeFormData(e)}
                                                required
                                                error={invalid && !formData?.password ? {
                                                    content: Errors.password,
                                                    pointing: 'below',
                                                } : false}
                                            />
                                            :
                                            <>
                                                <label>Password</label>
                                                <Button
                                                    icon
                                                    labelPosition='left'

                                                    onClick={() => setChangePassword(true)}
                                                >
                                                    <Icon name='lock' />
                                                    Change password
                                                </Button>
                                            </>
                                        }
                                    </Form.Field>
                                </Grid.Column>
                            }

                        </Grid.Row>
                    </Grid>
                </div>
                <section style={{
                    marginTop: '2em',
                    background: '#E6F4FA',
                    padding: '20px 0px'
                }}>
                    <div
                        className="ui container"
                    >
                        <div style={{ marginTop: '2em' }}>

                            <Grid>
                                <Grid.Row columns={3} stretched>
                                    <Grid.Column width={10}>
                                        <Form.Field
                                            label="Company name"
                                            icon="building"
                                            iconPosition='left'
                                            name="companyName"
                                            value={formData.companyName || ''}
                                            onChange={e => changeFormData(e)}
                                            control={Input}
                                            error={invalid && !formData?.companyName ? {
                                                content: Errors.required,
                                                pointing: 'below',
                                            } : false}
                                            required
                                        />
                                    </Grid.Column>
                                    <Grid.Column width={6}>
                                        <Form.Field
                                            label="Company phone"
                                            icon="phone"
                                            iconPosition='left'
                                            name="companyPhoneNumber"
                                            value={formData.companyPhoneNumber || ''}
                                            onChange={e => changeFormData(e)}
                                            control={Input}
                                            required
                                            error={invalid && !formData?.companyPhoneNumber ? {
                                                content: Errors.required,
                                                pointing: 'below',
                                            } : false}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row columns={2} stretched>
                                    <Grid.Column>
                                        <Form.Field
                                            label="Company address"
                                            icon="home"
                                            iconPosition='left'
                                            name="companyAddress"
                                            value={formData.companyAddress || ''}
                                            onChange={e => changeFormData(e)}
                                            control={Input}
                                            required
                                            error={invalid && !formData?.companyAddress ? {
                                                content: Errors.required,
                                                pointing: 'below',
                                            } : false}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                                <Grid.Row columns={3} stretched>
                                    <Grid.Column>
                                        <Form.Field
                                            label="Company postal code"
                                            icon="map marker alternate"
                                            iconPosition='left'
                                            name="companyPostalCode"
                                            value={formData.companyPostalCode || ''}
                                            onChange={e => changeFormData(e)}
                                            control={Input}
                                            required
                                            error={invalid && !formData?.companyPostalCode ? {
                                                content: Errors.required,
                                                pointing: 'below',
                                            } : false}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field
                                            label="Company city"
                                            icon="globe"
                                            iconPosition='left'
                                            name="companyCity"
                                            value={formData.companyCity || ''}
                                            onChange={e => changeFormData(e)}
                                            control={Input}
                                            required
                                            error={invalid && !formData?.companyCity ? {
                                                content: Errors.required,
                                                pointing: 'below',
                                            } : false}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <Form.Field
                                            label="Company Country"
                                            icon="globe"
                                            iconPosition='left'
                                            name="companyCountry"
                                            value={formData.companyCountry || ''}
                                            onChange={e => changeFormData(e)}
                                            control={Input}
                                            required
                                            error={invalid && !formData?.companyCountry ? {
                                                content: Errors.required,
                                                pointing: 'below',
                                            } : false}
                                        />
                                    </Grid.Column>
                                </Grid.Row>

                            </Grid>


                        </div >
                    </div >
                </section>

                <div
                    className="ui container"
                >
                    <Grid style={{ marginTop: '2em' }}>

                        <Grid.Row columns={3} stretched>
                            <Grid.Column>
                                <Form.Field
                                    label="Contact person name"
                                    icon="user"
                                    iconPosition='left'
                                    name="contact"
                                    value={formData.contact || ''}
                                    onChange={e => changeFormData(e)}
                                    control={Input}
                                    required
                                    error={invalid && !formData?.contact ? {
                                        content: Errors.required,
                                        pointing: 'below',
                                    } : false}
                                />

                            </Grid.Column>
                            <Grid.Column>
                                <Form.Field
                                    label="Mobile phone number"
                                    icon="mobile alternate"
                                    iconPosition='left'
                                    name="mobilePhoneNumber"
                                    value={formData.mobilePhoneNumber || ''}
                                    onChange={e => changeFormData(e)}
                                    control={Input}
                                    required
                                    error={invalid && !formData?.mobilePhoneNumber ? {
                                        content: Errors.required,
                                        pointing: 'below',
                                    } : false}
                                >
                                </Form.Field>
                            </Grid.Column>
                        </Grid.Row>

                        {isAdmin &&
                            <>
                                <Grid.Row columns={4} stretched>
                                    <Grid.Column>
                                        <label><b>Active</b></label>
                                        <Checkbox
                                            toggle
                                            checked={formData.active || false}
                                            onChange={(e, data) => {
                                                setFormData({
                                                    ...formData, ["active"]: data.checked
                                                })
                                            }}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <label><b>Billable</b></label>
                                        <Checkbox
                                            toggle
                                            checked={formData.billable || false}
                                            onChange={(e, data) => setFormData({
                                                ...formData, ["billable"]: data.checked
                                            })}
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <label><b>Accountant</b></label>
                                        <Checkbox
                                            toggle
                                            checked={formData.accountant || false}
                                            onChange={(e, data) => setFormData({
                                                ...formData, ["accountant"]: data.checked
                                            })}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                                <Grid.Row columns={3} stretched>
                                    <Grid.Column>
                                        <label><b>Network Affiliation</b></label>
                                        <Dropdown
                                            placeholder='All Network affiliations'
                                            search
                                            selection
                                            options={optionsNetworkAffiliation}
                                            value={formData.networkAffiliation_id || ''}
                                            onChange={(e, data) => {
                                                setFormData({
                                                    ...formData, [`networkAffiliation_id`]: data.value
                                                })
                                            }}
                                            clearable
                                        />
                                    </Grid.Column>
                                    <Grid.Column>
                                        <label><b>Contract expiration type</b></label>
                                        <Dropdown
                                            search
                                            selection
                                            value={formData.contractExpirationType_id || ''}
                                            options={optionsContractExpirationTypes}
                                            onChange={(e, data) => {
                                                setFormData({
                                                    ...formData, [`contractExpirationType_id`]: data.value
                                                })
                                            }}
                                            clearable
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </>
                        }

                        <Grid.Row columns={3}>
                            <Grid.Column>
                                <Button
                                    color="blue"
                                    icon
                                    labelPosition='left'
                                    disabled={loading}
                                    name={signUp ? "Sign up" : "Save Changes"}
                                >
                                    <Icon name='save' />
                                    {signUp ? "Sign up" : "Save Changes"}
                                </Button>
                            </Grid.Column>
                        </Grid.Row>

                    </Grid>
                </div>
            </Form >
        </>
    )
}
