import PageHeader from "../Components/pageHeader";
import { Segment, Table } from 'semantic-ui-react'

export default function ClaimsPayableProcedure() {
    return (
        <div className="ui container" style={{ marginTop: '2em' }}>
            
            <PageHeader
                title={"Claims payable procedure"}
                icon={"home"}
            />

            <p>
                <strong>CLAIMS PAYABLE PROCEDURE</strong>
            </p>

            <p>
                All claims are payable by <strong>insurers</strong>. This means that
                <strong>insurers</strong> will make payment through the Insurance Broker.
            </p>

            <p>
                <strong>Your obligations in the event of a claim</strong>
            </p>

            <Segment>
                <p>
                    IF <strong>YOU</strong> DO NOT COMPLY WITH <strong>YOUR</strong>
                    OBLIGATIONS, <strong>INSURERS</strong> MAY BE DISCHARGED FROM LIABILITY
                    UNDER THIS <strong>POLICY</strong>.
                </p>
                <p>
                    THIS MEANS THAT <strong>YOU</strong> MAY NOT BE COVERED FOR ANY LOSS
                </p>
            </Segment>

            <p>
                <strong>You</strong> must adhere to the following procedure.
            </p>

            <p>
                In the event of a loss covered by the <strong>policy</strong>,
                <strong>you</strong> must act sensibly, as if <strong>your</strong> loss
                was not covered by insurance. <strong>You</strong> must act to minimise the
                loss and/or damage to the <strong>goods</strong> and to protect any
                recovery rights that may be available.
            </p>

            <p>
                In the event of any happening or event likely to give rise to a claim under
                this <strong>policy</strong>, <strong>you</strong> must ensure that
                <strong>we</strong> are informed about the event as soon as possible but in
                any event within seven (7) working days from discovery.
                <strong>You</strong> must also give notice in writing as soon as reasonably
                practicable. This notice should be given, with full particulars, to your
                Broker of which full name and contact details are printed on the back of
                the Certificate of Insurance.
            </p>

            <p>
                <strong>You</strong> must then take the following steps:
            </p>

            <ol>
                <li>
                    Submit as soon as possible all written particulars, supporting
                    documentation and correspondence regarding the event including
                    invoices, statements or other documents evidencing the amount being
                    claimed. For further information on this obligation, see “Documentation
                    of Claims” below.
                </li>
                <li>
                    Take reasonable measures to avoid or minimise any loss, damage or
                    expense. <strong>Insurers</strong> will pay the costs of such measures
                    provided that they are both reasonable and necessary.
                </li>
                <li>
                    Properly preserve and exercise all rights against carriers, bailees or
                    other third parties, specifically:
                    <ol>
                        <li>
                            Do not release those parties from liability.
                        </li>
                        <li>
                            <strong>You</strong> must not admit, or promise payment to
                            anyone without <strong>our</strong> written consent.
                        </li>
                        <li>
                            Deliver to the parties responsible a notice of intention to
                            claim within 3 days of delivery.
                        </li>
                        <li>
                            Do not give clean receipts where the <strong>subject matter
                                insured</strong> is in doubtful condition except under written
                            protest.
                        </li>
                        <li>
                            If a consignment is delivered damaged or with <strong>subject
                                matter insured</strong> missing mark the delivery note
                            accordingly.
                        </li>
                        <li>
                            Where delivery is made by container, ensure that the seals are
                            examined immediately by a responsible official.
                        </li>
                        <li>
                            If any package is delivered damaged, e.g. torn, wet damaged,
                            note the delivery note accordingly and retain all packaging for
                            subsequent inspection. If possible take photos of the damaged
                            package(s) to assist with <strong>your</strong> claim.
                        </li>
                        <li>
                            If a container is delivered damaged or with seals broken or
                            missing or with seals other than stated in the shipping
                            documentation, mark the delivery note accordingly and retain
                            the seals for subsequent identification.
                        </li>
                        <li>
                            Upon discovery of any loss or damage, apply immediately for
                            surveys by the carriers or other bailees to be conducted within
                            3 days of delivery.
                        </li>
                    </ol>
                </li>
                <li>
                    Inform the police as soon as possible after a theft has occurred.
                </li>
                <li>
                    In the event of a general average or salvage contribution arising under
                    this policy, consult insurers or insurers' nominated settling agent
                    before signing any general average or salvage bond.
                </li>
            </ol>

            <p>
                <strong>Fraudulent Claims</strong>
            </p>

            <p>
                If any claim is fraudulent or false or intentionally exaggerated (whether
                ultimately material or not) in any respect, <strong>insurers</strong> may
                refuse to pay the whole or part of the claim to the extent permitted by
                law, and also may be entitled to void or cancel the
                <strong>policy</strong>.
            </p>

            <p>
                <strong>Documentation of claims</strong>
            </p>

            <p>
                To enable claims to be dealt with promptly and to avoid prejudicing
                <strong>your</strong> claim under the <strong>policy</strong>,
                <strong>you</strong> are required to submit all available supporting
                documents to <strong>insurers</strong> or the nominated Insurance Broker:
            </p>

            <Table collapsing>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell colSpan='2'>Maesstad B.V.</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colSpan='2'>Jan Leentvaarlaan 35</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell colSpan='2'>3065 DC Rotterdam</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><b>Telephone</b></Table.Cell>
                        <Table.Cell>(+31) 10 2062255</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                        <Table.Cell><b>E-mail</b></Table.Cell>
                        <Table.Cell>claims@maesstad.nl</Table.Cell>
                    </Table.Row>
                </Table.Body>
            </Table>

            <p>
                or appointed <strong>Lloyd's agent</strong> without delay, including when
                applicable:
            </p>

            <ol>
                <li>
                    Original certificate of insurance
                </li>
                <li>
                    Original or copy shipping invoices, together with shipping
                    specification and/or weight notes, indicating quantity and value
                </li>
                <li>
                    Original bill of lading and/or other contract of carriage
                </li>
                <li>
                    Any survey report or other documentary evidence to show the extent of
                    the loss or damage and/or landing account and weight notes at final
                    destination
                </li>
                <li>
                    Correspondence exchanged with the carriers and other parties regarding
                    their liability for the loss or damage.
                </li>
            </ol>

            <p>
                <strong>Subrogation Clause</strong>
            </p>

            <p>
                When <strong>insurers</strong> settle a claim, they may pursue recovery
                rights against a carrier or third party who caused the loss or damage to
                the <strong>subject matter insured</strong>. The <strong>Assured</strong>
                agree that:
            </p>

            <ol>
                <li>
                    <strong>Insurers</strong> may act in the <strong>Assured's</strong>
                    name in such recovery action; and
                </li>
                <li>
                    The <strong>Assured</strong> will give <strong>insurers</strong>
                    reasonable assistance with such actions.
                </li>
            </ol>

            <p>
                <strong>Waiver Clause</strong>
            </p>

            <p>
                Measures taken by the <strong>Assured</strong> or <strong>insurers</strong>
                with the object of saving, protecting or recovering the <strong>subject
                    matter insured</strong> shall not be considered as a waiver or acceptance
                of abandonment or otherwise prejudice the rights of either party.
            </p>

            <p>
                <strong>Waiver of Rights Clause</strong>
            </p>

            <p>
                Where another person(s) is liable to compensate the
                <strong>Assured</strong> for any loss or damage covered by this
                <strong>policy</strong> but the <strong>Assured</strong> have previously
                agreed not to seek recovery from that person(s), then
                <strong>insurers</strong> will reduce their liability under the
                <strong>policy</strong> contract.
            </p>

            <p>
                <strong>Waiver of Survey Clause</strong>
            </p>

            <p>
                Once claims details have been submitted for <strong>insurers'</strong>
                consideration, then subject to their approval, it is agreed to waive
                surveys in respect of claims not expected to exceed USD1,000.00 or
                equivalent in other currencies, net of cover deductible, if any, such
                claims to be accepted by <strong>insurers</strong> on presentation of the
                <strong>Assured's</strong> statement and supporting documentation only.
            </p>

        </div>

    )
}
