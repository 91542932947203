import { type } from '@testing-library/user-event/dist/type';
import { forEach } from 'lodash';
import { toast } from 'react-toastify';

export function displayErrors(errors) {

    Object.values(errors).forEach(function showToast(error, index) {

        let message;

        if (typeof (error) == "object" && error.hasOwnProperty('message')) {
            message = error.message
        } else {
            message = error.toString();
        }

        toast.error(
            message, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
        }
        );
    })

}

export function displaySuccess(message) {
    toast.success(
        message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
    }
    );

}