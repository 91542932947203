import { Navigate, Outlet } from 'react-router-dom';

export function ProtectedRoute(properties) {

    const {
        isAllowed,
        redirectPath = '/login',
        children
    } = properties

    if (!isAllowed) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;

}