import { Table, Input, Form } from 'semantic-ui-react'
import { useEffect, useState } from 'react';

export default function TableContinents(properties) {

    const {
        displayValues = false,
        merchandiseType = 0,
        networkInterestRates = [],
    } = properties;

    const continents = properties.continents;

    const TableHeaderRows = () => {
        return continents.map((continent, index) => {

            if (continent.name == "Antarctica") return null;

            return <Table.HeaderCell key={index}>dest {continent.name}</Table.HeaderCell>
        }
        )
    }

    const TableRows = () => {
        return continents.map((continent, sourceIndex) => {

            const sourceId = continent.id;

            if (continent.name == "Antarctica") return null;

            return (
                <Table.Row key={sourceIndex}>
                    <Table.Cell>source {continent.name}</Table.Cell>
                    {continents.map((continent_, destinationIndex) => {

                        if (continent_.name == "Antarctica") return null;

                        const destinationId = continent_.id;
                        const fieldKey = merchandiseType * continents.length * continents.length + continents.length * sourceIndex + destinationIndex
                        
                        const defaultValue = networkInterestRates.find(
                            interestRate =>
                            interestRate.destinationContinent_id == destinationId &&
                            interestRate.sourceContinent_id == sourceId &&
                            interestRate.merchandiseType_id == merchandiseType
                        )?.valuePerMillion || 0;

                        const cellProperties = {
                            key:        destinationIndex,
                            textAlign:  displayValues ? 'right' : 'left'
                        };

                        return (
                            <Table.Cell { ... cellProperties }>

                                <input type="hidden" name={`interestRates[${fieldKey}][sourceContinentId]`} value={sourceId} />
                                <input type="hidden" name={`interestRates[${fieldKey}][destinationContinentId]`} value={destinationId} />
                                <input type="hidden" name={`interestRates[${fieldKey}][merchandiseTypeId]`} value={merchandiseType} />

                                {displayValues
                                ? `${(defaultValue / (1e6 / 100)).toFixed(4)}%`
                                : <Form.Field
                                    control={Input}
                                    defaultValue={defaultValue}
                                    name={`interestRates[${fieldKey}][valuePerMillion]`}
                                    required
                                    type='number'
                                /> }
                            </Table.Cell>
                        )
                    })}

                </Table.Row>
            )
        })
    }

    return (
        <Table
            basic='very'
            striped
            style={{
                marginTop: '1em',
                marginBottom: '4em'
            }}
        >
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Continent</Table.HeaderCell>
                    <TableHeaderRows />
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <TableRows />
            </Table.Body>
        </Table>
    )
}
