import { useState, useEffect, useContext } from "react";
import PageHeader from "../Components/pageHeader";
import { Dropdown, Grid, Button, Icon, Table, Label } from 'semantic-ui-react'
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import Modal from "../Components/modal";
import { DateTime } from "luxon";
import { UserContext } from '../Components/userContext';
import {
    cargoCertificatesDataRequest,
    usersDataRequest,
    networksDataRequest,
    merchandiseTypesDataRequest,
    currenciesDataRequest,
    removeCertificateRequest
} from "../api/backend";
import { DownloadCertificate, DownloadBookingSlip, DownloadCertificatesXLS } from "../helpers/downloads";
import { useNavigate } from 'react-router-dom'
import { displayErrors, displaySuccess } from '../Components/displayNotifications';
import usePagination from '../Components/usePagination.jsx';

export default function CargoCertificates(properties) {

    const {
        title = "Cargo Certificates",
        isAdmin = false,
        isAccountant = false,
    } = properties;

    const navigate = useNavigate();

    const [sentinel, setSentinel] = useState('')
    const [removeCertificate, setRemoveCertificate] = useState({});
    const [optionsNetworkAffiliation, setOptionsNetworkAffiliation] = useState([]);
    const [optionsCurrencies, setOptionsCurrencies] = useState([]);
    const [filterNetworkAffiliation, setFilterNetworkAffiliation] = useState('');
    const [filterCurrency, setFilterCurrency] = useState('');
    const [filterFromDate, setFilterFromDate] = useState('')
    const [filterToDate, setFilterToDate] = useState('')

    const [openModal, setOpenModal] = useState(false);

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [merchandiseTypes, setMerchandiseTypes] = useState([]);

    const cargoCertificates = usePagination(fetchContracts)([
        filterCurrency,
        filterFromDate,
        filterNetworkAffiliation,
        filterToDate,
        sentinel,
        setLoading
    ]);

    // ================================================= Retrieve data required for the filter options
    useEffect(() => {

        // Only admins can filter
        if (!isAdmin) return;

        const fetchData = async () => {

            setLoading(true);

            const requestUsers = await usersDataRequest();
            const requestNetworks = await networksDataRequest();
            const requestMerchandiseTypes = await merchandiseTypesDataRequest();
            const requestCurrencies = await currenciesDataRequest();

            if ([200, 201, 204].includes(requestUsers.status)) {
                setUsers(await requestUsers.json());
            }

            if ([200, 201, 204].includes(requestMerchandiseTypes.status)) {
                setMerchandiseTypes(await requestMerchandiseTypes.json());
            }

            if ([200, 201, 204].includes(requestCurrencies.status)) {
                const currencies = await requestCurrencies.json();
                setOptionsCurrencies(
                    currencies.map((currency, index) => {
                        return {
                            key: index,
                            text: currency.name,
                            value: currency.id
                        }
                    })
                )
            }

            if ([200, 201, 204].includes(requestNetworks.status)) {

                const networks = await requestNetworks.json();
                setOptionsNetworkAffiliation(
                    networks.map((network, index) => {
                        return {
                            key: index,
                            text: network.name,
                            value: network.id
                        }
                    })
                )

            }

            setLoading(false);
        }

        fetchData();

    }, [])

    // ================================================= DELETE certificates data
    const handleRemoveSelectedCertificate = async (certificateToRemove) => {

        setLoading(true);

        const request = await removeCertificateRequest(certificateToRemove);

        if ([200, 201, 204].includes(request.status)) {
            setRemoveCertificate({});
            setSentinel(Symbol());
            displaySuccess("Certificate removed succesfully");
            setLoading(false);
        } else {
            const response = await request.json();
            displayErrors(response);
        }

        setLoading(false);

    }


    return (
        <div className="ui container" style={{ marginTop: '2em' }}>
            <PageHeader
                title={title}
                icon="file"
            />
            {isAdmin &&
                <Grid style={{ marginTop: "2em" }}>
                    <Grid.Row columns={2} stretched>
                        <Grid.Column>
                            <label><b>Search by network affiliation</b></label>
                            <Dropdown
                                placeholder='All Network affiliations'
                                search
                                selection
                                options={optionsNetworkAffiliation}
                                onChange={(event, data) => setFilterNetworkAffiliation(data.value)}
                                clearable
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <label><b>Search by currency</b></label>
                            <Dropdown
                                placeholder='All Currencies'
                                search
                                selection
                                options={optionsCurrencies}
                                value={filterCurrency}
                                onChange={(event, data) => setFilterCurrency(data.value)}
                                clearable
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={5} stretched>
                        <Grid.Column>
                            <label><b>Search by date (from)</b></label>
                            <SemanticDatepicker
                                format="D/M/YYYY"
                                value={filterFromDate}
                                onChange={(event, data) => setFilterFromDate(data.value)}
                            />
                        </Grid.Column>
                        <Grid.Column>
                            <label><b>Search by date (to)</b></label>
                            <SemanticDatepicker
                                format="D/M/YYYY"
                                value={filterToDate}
                                onChange={(event, data) => setFilterToDate(data.value)}
                            />
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Column>
                            <Button
                                color="orange"
                                icon
                                labelPosition='left'
                                onClick={e => DownloadCertificatesXLS({ filterNetworkAffiliation, filterCurrency, filterFromDate, filterToDate })}
                            >
                                <Icon name='download' />
                                Download certificates
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }

            {!isAdmin && !isAccountant &&
                <Grid style={{ marginTop: "2em" }}>
                    <Grid.Row>
                        <Grid.Column>
                            <Button
                                onClick={() => navigate('/cargo-certificates/create')}
                                color="green"
                                icon
                                labelPosition='left'
                            >
                                <Icon name='plus' />
                                Create a new certificate
                            </Button>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            }

            <Table
                basic='very'
                style={{ marginTop: '4em' }}
            >
                <Table.Header>
                    <Table.Row>
                        {isAdmin &&
                            <>
                                <Table.HeaderCell>Exported</Table.HeaderCell>
                                <Table.HeaderCell>Company</Table.HeaderCell>
                                <Table.HeaderCell>Type</Table.HeaderCell>
                            </>
                        }
                        {!isAdmin &&
                            <Table.HeaderCell collapsing>Status</Table.HeaderCell>
                        }
                        <Table.HeaderCell>Departure</Table.HeaderCell>
                        <Table.HeaderCell>From</Table.HeaderCell>
                        <Table.HeaderCell>To</Table.HeaderCell>
                        <Table.HeaderCell>Description</Table.HeaderCell>
                        <Table.HeaderCell>View</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {cargoCertificates.resultset.status === 'resolved' && cargoCertificates.resultset.value.map((certificate, index) => {

                        const companyName = users.find(user => certificate.contractorId == user.id)?.companyName || "Unkown";
                        const merchandiseType = merchandiseTypes.find(type => certificate.merchandiseTypeId == type.id)?.value || "Unkown";

                        return (
                            <Table.Row
                                key={index}
                            >
                                {isAdmin &&
                                    <>
                                        <Table.Cell>
                                            <Label
                                                color={certificate.exported ? "green" : "black"}
                                            >
                                                <Icon style={{ margin: "0px" }} name={certificate.exported ? "check" : "remove"} />
                                            </Label>
                                        </Table.Cell>
                                        <Table.Cell>{companyName}</Table.Cell>
                                        <Table.Cell>{merchandiseType}</Table.Cell>
                                    </>
                                }
                                {!isAdmin &&
                                    <Table.Cell collapsing>
                                        <Label
                                            color={certificate.draft ? "black" : "green"}
                                        >
                                            <Icon name='file' />
                                            {certificate.draft ? "DRAFT" : "FINAL"}
                                        </Label>
                                    </Table.Cell>
                                }
                                <Table.Cell>
                                    {DateTime.fromSQL(certificate.departureDate).toFormat('d/L/y')}
                                </Table.Cell>
                                <Table.Cell>{certificate.sourceCity}</Table.Cell>
                                <Table.Cell>{certificate.destinationCity}</Table.Cell>
                                <Table.Cell>{certificate.description}</Table.Cell>
                                <Table.Cell>
                                    <Dropdown
                                        icon='cog'
                                        basic
                                        direction="left"
                                        button
                                        className='icon'
                                        floating
                                    >
                                        <Dropdown.Menu>
                                            {!isAdmin && !isAccountant &&
                                                <Dropdown.Item onClick={() => navigate(`/cargo-certificates/${certificate.id}`)}><Icon name="edit" />Edit</Dropdown.Item>
                                            }
                                            {!isAccountant &&
                                                <Dropdown.Item
                                                    onClick={e => {
                                                        setOpenModal(true)
                                                        setRemoveCertificate({
                                                            id: certificate.id,
                                                            companyName: companyName
                                                        })
                                                    }}
                                                >
                                                    <Icon name="trash" />
                                                    Delete
                                                </Dropdown.Item>
                                            }
                                            <Dropdown.Item onClick={e => DownloadCertificate(certificate.id)}><Icon name="download" />Certificate</Dropdown.Item>
                                            <Dropdown.Item onClick={e => DownloadBookingSlip(certificate.id)}><Icon name="download" />Booking slip</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Table.Cell>
                            </Table.Row>
                        )
                    })}
                    {cargoCertificates.resultset.status !== 'resolved' && (
                        <Table.Row>
                            <Table.Cell>
                                Loading
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>

            <Modal
                open={openModal}
                title={"Are you sure?"}
                description={`Are you sure you want to delete certificate from company ${removeCertificate.companyName}`}
            >
                <Button
                    negative
                    onClick={() => setOpenModal(false)}
                >
                    Cancel
                </Button>
                <Button
                    positive
                    onClick={() => {
                        handleRemoveSelectedCertificate(removeCertificate.id || 0);
                        setOpenModal(false);
                    }}
                >
                    <Icon name='trash' />
                    Remove
                </Button>

            </Modal>

            {cargoCertificates.count.status === 'resolved' && cargoCertificates.count.value > cargoCertificates.pageSize && (
                <Grid
                    centered
                    style={{
                        marginTop: "2em"
                    }}
                >
                    <cargoCertificates.Pagination
                        boundaryRange={0}
                        ellipsisItem={null}
                        firstItem={null}
                        lastItem={null}
                        siblingRange={1}
                    />
                </Grid>
            )}

        </div>
    )
}

/**
 * Parameters (Null | Integer, Null | Date, Null | Integer, Null | Integer, Symbol, Boolean -> *) -> Promise Void
 * @param parameters Parameters (Null | Integer, Null | Date, Null | Integer, Null | Integer, Symbol, Boolean -> *)
 * @return Promise Void
 */
const fetchContracts = async parameters => {

    const { dependencies }  = parameters;
    const { pageIndex }     = parameters;
    const { pageSize }      = parameters;

    const filterCurrency            = dependencies[0];
    const filterFromDate            = dependencies[1];
    const filterNetworkAffiliation  = dependencies[2];
    const filterToDate              = dependencies[3];
    const setLoading                = dependencies[5];

    try {

        setLoading(true);

        const response = await cargoCertificatesDataRequest({

            filterNetworkAffiliation,
            filterCurrency,
            filterFromDate,
            filterToDate,

            limit:  pageSize,
            skip:   pageIndex * pageSize,

            order: [
                ['creationTime', -1]
            ]

        });

        if (!response.ok) {

            const contentType = response.headers.get('Content-Type');

            if (isJsonMediaType(contentType)) {

                const responseBody = await response.json();

                throw new Error(responseBody.error?.message ?? response.statusText);

            }

            throw new Error(await response.text());

        }

        let count;

        count = response;
        count = count.headers;
        count = count.get('Content-Range');
        count = count.match(/^items\s+(\d+)$/);
        count = count[1];

        const items = await response.json();

        return { count, items };

    } finally {
        setLoading(false);
    }

};

/**
 * String -> Boolean
 * @param value String
 * @return Boolean
 */
const isJsonMediaType = value => /^application\/json(;.*)?$/i.test(value);
