import { useState } from 'react';

export default function useSession() {

    const getSession = () => {

        const tokenString = sessionStorage.getItem('token');
        const userIdString = sessionStorage.getItem('userId');
        const userRoleString = sessionStorage.getItem('userRole');

        const userToken = tokenString;
        const userId = userIdString;
        const userRole = userRoleString;

        const sessions = {
            "token": userToken || false,
            "userId": userId || false,
            "userRole": userRole || false,            
        }

        return sessions ? sessions : false;

    };

    const [session, setSession] = useState(getSession());

    const saveSession = session => {

        sessionStorage.setItem('token', session.token);
        sessionStorage.setItem('userId', session.userId);
        sessionStorage.setItem('userRole', session.userRole);

        setSession({
            "token": session.token,
            "userId": session.userId,
            "userRole": session.userRole,  
        });
    };

    const clearSession = () => {

        sessionStorage.removeItem('token');
        sessionStorage.removeItem('userId');
        sessionStorage.removeItem('userRole');

        setSession({
            "token": false,
            "userId": false,
            "userRole": false
        });
    };

    return {
        clearSession : clearSession,
        setSession: saveSession,
        session
    }
}