import PageHeader from "../Components/pageHeader";
import { Button, Input, Grid, Form } from 'semantic-ui-react'
import { userLoginRequest, userDataRequest } from '../api/backend';
import { useState, useContext } from "react";
import { Errors } from "../Constants/errors";
import { useNavigate } from "react-router-dom";
import { UserContext } from '../Components/userContext';
import { displayErrors } from "../Components/displayNotifications";


export default function Login(properties) {

    const { setSession } = properties;
    const navigate = useNavigate();

    const [emailAddress, setEmailAddress] = useState('');
    const [password, setPassword] = useState('');

    const setSentinel = useContext(UserContext).setSentinel;
    const setLoadingUserContext = useContext(UserContext).setLoading;

    const [invalid, setInvalid] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmitLogin = async (e) => {

        e.preventDefault();
        setLoading(true);

        // let hasError = false;

        if (!e.target.checkValidity()) {
            setInvalid(true);
            setLoading(false);
            return null;
        }

        const formData = {
            emailAddress: emailAddress,
            password: password
        }

        const loginRequest = await userLoginRequest(formData);

        if ([200, 201, 204].includes(loginRequest.status)) {


            const loginData = await loginRequest.json();

            setSession({
                "token": loginData.accessToken,
                "userId": loginData.userId,
            })

            setLoadingUserContext(true);
            setSentinel(Symbol());

            navigate('/');
            setLoading(false);
        } else {
            const response = await loginRequest.json();
            displayErrors(response);
        }

        setLoading(false);

    }

    return (
        <div className="ui container" style={{ marginTop: '2em' }}>
            <PageHeader
                title={"Login"}
                icon={"user"}
            />
            <div style={{ marginTop: '2em' }}>
                <Form
                    onSubmit={handleSubmitLogin}
                    noValidate
                >
                    <Grid>
                        <Grid.Row columns={3} stretched>
                            <Grid.Column>
                                <Form.Field
                                    icon={'user'}
                                    control={Input}
                                    iconPosition={'left'}
                                    label={"E-mail address"}
                                    value={emailAddress}
                                    onChange={e => setEmailAddress(e.target.value)}
                                    required
                                    error={invalid && !emailAddress ? {
                                        content: Errors.email,
                                        pointing: 'below',
                                    } : false}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={3} stretched>
                            <Grid.Column>
                                <Form.Field
                                    icon={'lock'}
                                    control={Input}
                                    iconPosition={'left'}
                                    label={"Password"}
                                    value={password}
                                    type="password"
                                    onChange={e => setPassword(e.target.value)}
                                    required
                                    error={invalid && !password ? {
                                        content: Errors.password,
                                        pointing: 'below',
                                    } : false}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={3} stretched>
                            <Grid.Column>
                                <Button
                                    color="orange"
                                    disabled={loading}
                                >
                                    Log in
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={3} stretched>
                            <Grid.Column>
                                <a href="/sign-up">Sign up</a>
                                <a href="/forgot-password">Forgot password</a>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </div>
        </div>
    )
}