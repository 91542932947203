import PageHeader from "../Components/pageHeader";
import { useState, useEffect } from "react";
import { Image, Tab, Table } from 'semantic-ui-react'
import { getCurrentSessionCertificatePrices, getCurrentSessionMinimumPremiums, getCurrentSessionCurrencies, networkInterestRatesDataRequest, merchandiseTypesDataRequest, continentsDataRequest } from '../api/backend';
import { getCurrentSessionNetwork } from '../api/backend.js';
import TableContinents from "../Components/tableContinents";

export default function InterestRates() {

    const [loading, setLoading] = useState(false);
    const [network, setNetwork] = useState({ status: 'pending' });
    const [optionsContinents, setOptionsContinents] = useState([]);
    const [networkInterestRates, setNetworkInterestRates] = useState([]);
    const [optionsMerchandiseTypes, setOptionsMerchandiseTypes] = useState([]);
    const [currencies, setCurrencies] = useState(false)

    // ================================================= Retrieve data required for the select options
    useEffect(() => {

        const fetchData = async () => {

            setLoading(true);

            const networkRequestPromise = getCurrentSessionNetwork({
                projection: [
                    'name'
                ]
            });

            const requestContinents = await continentsDataRequest();
            const requestMerchandiseTypes = await merchandiseTypesDataRequest();
            const requestNetworkInterestRates = await networkInterestRatesDataRequest(false, true);
            const requestSessionCurrencies = await getCurrentSessionCurrencies({
                projection: [
                    'code',
                    'id',
                    'name',
                    'symbol'

                ]
            });
            const requestSessionMinimumPremiums = await getCurrentSessionMinimumPremiums({});
            const requestSessionCertificatePrice = await getCurrentSessionCertificatePrices({});

            const networkRequest = await networkRequestPromise;

            if (networkRequest.ok) {

                const network = await networkRequest.json();

                setNetwork({
                    status: 'resolved',
                    value:  network
                });

            }

            if ([200, 201, 204].includes(requestContinents.status)) {
                setOptionsContinents(await requestContinents.json());
            }

            if ([200, 201, 204].includes(requestMerchandiseTypes.status)) {
                setOptionsMerchandiseTypes(await requestMerchandiseTypes.json());
            }

            if ([200, 201, 204].includes(requestNetworkInterestRates.status)) {
                setNetworkInterestRates(await requestNetworkInterestRates.json())
            }

            if (
                [200, 201, 204].includes(requestSessionCurrencies.status) &&
                [200, 201, 204].includes(requestSessionMinimumPremiums.status) &&
                [200, 201, 204].includes(requestSessionCertificatePrice.status)) {

                const sessionsCurrencies = await requestSessionCurrencies.json();
                const sessionsMinimumPremiums = await requestSessionMinimumPremiums.json();
                const sessionsCertificatePrice = await requestSessionCertificatePrice.json();

                setCurrencies(sessionsCurrencies.map((currency, index) => {

                    const minimumPremiums = sessionsMinimumPremiums.find(premium => premium.currency_id == currency.id).value;
                    const certificatePrice = sessionsCertificatePrice.find(certificate => certificate.currency_id == currency.id).value;

                    return {
                        code: currency.code,
                        minimumPremiums: minimumPremiums,
                        certificatePrice: certificatePrice
                    }

                }));

            }

            setLoading(false);
        }

        fetchData();

    }, [])

    const tableDescription = {
        0: "New General Approved Goods and/or Merchandise of every description properly packed for the nature of the goods and the transit, not particularly susceptible to breakage theft, water damage, leakage and shortage or chipping.",
        1: "Cookers, refrigerators, freezers, washing machines and other white or enamel goods, radio and TV equipment, calculators and the like, perfumery articles, furs, spirits, branded sports and leisure footwear, clothing, accessories and the like properly packed for the nature of the goods so as to be suitable for the intended transit.",
        2: "Household goods, personal effects, motor vehicles, artwork, antiques, etc. and all shipments for private individuals."
    }

    return (
        <div className="ui container" style={{ marginTop: '2em' }}>

            <PageHeader
                title={"Interest rates"}
                icon={"grid layout"}
            />

            {network.status === 'resolved'
            ? <p>
                Commodity Groupings and Rating for the Marine Cargo Cover
                of {network.value.name}
            </p>
            : <div className="ui fluid placeholder">
                <div className="medium line" />
            </div> }

            <p>
                <b>Attention:</b>
                <br />
                <br />
                <b>Cover can only be provided if special acceptance is obtained prior to
                    shipment for the following goods</b>:-Bonds, deeds, documents,
                manuscripts, plans, securities, stamps, treasury notes -Bullion, cash -
                Jewelry, watches, precious metals and stones - Livestock and plants -
                Shipments in bulk -Second-hand Goods - Perishable, frozen, chilled goods.
            </p>

            {optionsMerchandiseTypes.map((type, index) => {
                return (
                    <div key={index} style={{ marginTop: "30px" }}>
                        <h2>{type.value}</h2>
                        <p>{tableDescription[type.id]}</p>
                        <TableContinents
                            continents={optionsContinents}
                            displayValues={true}
                            merchandiseType={type.id}
                            networkInterestRates={networkInterestRates}
                        />
                    </div>
                )
            })}

            {currencies &&
                <p>
                    Minimum premium per certificate {T(currencies)(pipe([
                        map(uncurryFoldable(liftN(2))([
                            surround(' '),
                            prop('code'),
                            prop('minimumPremiums')
                        ])),
                        intercalate(' / ')
                    ]))}
                    <br />
                    Intervention fee {T(currencies)(pipe([
                        map(uncurryFoldable(liftN(2))([
                            surround(' '),
                            prop('code'),
                            prop('certificatePrice')
                        ])),
                        intercalate(' / ')
                    ]))} per certificate
                </p>
            }

        </div>
    )
}

/**
 * F (A -> B) -> F A -> F B
 * F A = Functor A
 * @param F (A -> B)
 * @param F A
 * @return F B
 */
const ap = f => g => x => f(x)(g(x))

/**
 * (A -> B) -> (B -> C) -> A -> C
 * @param f A -> B
 * @param g B -> C
 * @return C
 */
const compose = f => g => x => f(g(x));

/**
 * S -> S -> S
 * S = Semigroup
 * @param x S
 * @param y S
 * @return S
 */
const concat = x => y => x.concat(y);

/**
 * F (A -> B) -> A -> F B
 * F A = Functor A
 * @parameter $structure F (A -> B)
 * @parameter $value A
 * @return F B
 */
const flip = structure => value => map(T(value))(structure);

/**
 * (B -> A -> B) -> B -> (Foldable | Iterable) A -> B
 * @param f B -> A -> B
 * @param initialValue B
 * @param structure (Foldable | Iterable) A
 * @return B
 *
 * (B -> A -> WrapperUnion Promise B) -> B -> AsyncIterable A -> Promise B
 * WrapperUnion W A = A | W A
 * @param f B -> A -> WrapperUnion Promise B
 * @param initialValue B
 * @param structure AsyncIterable A
 * @return Promise B
 */
const foldLeft = f => initialValue => structure => {
    return structure.reduce((x, y) => f(x)(y), initialValue);
};

/**
 * A -> A
 * @param x A
 * @return A
 */
const I = x => x;

/**
 * M -> Foldable M -> M
 * M = Monoid
 * @param delimiter M
 * @param structure Foldable M
 * @return M
 */
const intercalate = delimiter => structure => structure.join(delimiter);

/**
 * (A -> B -> B) -> A -> B -> A -> B
 * @param f A -> B -> B
 * @param x A
 * @param y B
 * @param z A
 * @return B
 */
const J = f => x => y => z => f(x)(f(z)(y));

/**
 * (A -> B) -> F A -> F B
 * F A = Functor A
 * @param f A -> B
 * @param structure F A
 * @return F B
 */
const map = f => structure => {
    if (Array.isArray (structure)) {
        return structure.map(f);
    } else {
        return compose(f)(structure);
    }
};

/**
 * (String | Symbol) -> Object A -> A
 * @param key String | Symbol
 * @param structure Object A
 * @return A
 */
const prop = key => structure => {
    if (key in structure) {
        return structure[key];
    } else {
        throw new TypeError(`\`prop\` expected object to have a property named \`${key}\`, ${JSON.stringify(structure)} does not`);
    }
}

/**
 * (A -> B) -> (B -> C) -> A -> C
 * @param f A -> B
 * @param g B -> C
 * @return C
 */
const Q = f => g => x => g(f(x));

/**
 * Integer -> A -> Iterable A
 * @param amount Integer
 * @param value A
 * @return Iterable A
 */
const repeatTimes = amount => value => Array(amount).fill(value);

/**
 * R -> R -> R
 * R = Ring
 * @param subtrahend R
 * @param minuend R
 * @return R
 */
const subtract = subtrahend => minuend => minuend - subtrahend;

/**
 * A -> (A -> B) -> B
 * @param x A
 * @param f A -> B
 * @return B
 */
const T = x => f => f(x);

/**
 * (A -> C -> B -> D) -> A -> B -> C -> D
 * @param f A -> C -> B -> D
 * @return A -> B -> C -> D
 */
const C$ = compose (flip)

/**
 * S -> S -> S -> S
 * S = Semigroup
 * @param x S
 * @param y S
 * @param delimiter S
 * @return S
 */
const enclose = J(concat);

/**
 * (Foldable | Iterable) (Unknown -> *) -> A -> B
 * @param functions (Foldable | Iterable) (Unknown -> *)
 * @return A -> B
 */
const pipe = foldLeft(Q)(I);

/**
 * (B -> C -> A -> D) -> A -> B -> C -> D
 * @param f B -> C -> A -> D
 * @return A -> B -> C -> D
 */
const R$ = C$(C$);

/**
 * (curriedN n A -> B) -> TupleN n A -> B
 * @param f curriedN n A -> B
 * @return TupleN n A -> B
 */
const uncurryFoldable = foldLeft(I);

/**
 * n -> (curriedN n A -> B) -> curriedN n (F A) -> F B
 * F A = Functor A
 * @param n n
 * @param f curriedN n A -> B
 * @return curriedN n (F A) -> F B
 */
const liftN = pipe([
    subtract(1),
    flip(repeatTimes)(ap),
    compose(T(map))(foldLeft(compose(compose(compose)(compose)))(I))
]);

/**
 * S -> S -> S -> S
 * S = Semigroup
 * @param value S
 * @param leftDelimiter S
 * @param rightDelimiter S
 * @return S
 */
const surround = R$(enclose);
