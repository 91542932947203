import { useState, useReducer, Children } from 'react'
import { Modal, Button } from 'semantic-ui-react'

export default function SemanticModal(properties) {

    const { children, open, title, description } = properties;

    return (
        <Modal
            // dimmer={dimmer}
            open={open}
            // onClose={() => dispatch({ type: 'CLOSE_MODAL' })}
        >
            <Modal.Header>{title}</Modal.Header>
            <Modal.Content>
                {description}
            </Modal.Content>
            <Modal.Actions>
                {children}
            </Modal.Actions>
        </Modal>
    )
}