import { Grid } from 'semantic-ui-react';
import { useState, useEffect } from 'react';
import PageHeader from "../Components/pageHeader";
import SemanticModal from "../Components/modal";
import { useNavigate } from 'react-router-dom'
import { Table, Icon, Dropdown, Checkbox, Button, Label } from 'semantic-ui-react';
import { removeUserRequest, usersDataRequest } from "../api/backend";
import { displayErrors, displaySuccess } from '../Components/displayNotifications';

export default function Users() {

    const [openModal, setOpenModal] = useState(false);

    const [loading, setLoading] = useState(false);
    const [sentinel, setSentinel] = useState('');
    const [removeUser, setRemoveUser] = useState({})
    const [users, setUsers]             = useState({ status: 'pending' });

    useEffect(() => void fetchUsers(setLoading, setUsers), [sentinel]);

    const navigate = useNavigate();

    const handleRemoveSelectedUser = async () => {

        setLoading(true);

        const request = await removeUserRequest(removeUser.id);

        if ([200, 201, 204].includes(request.status)) {
            setRemoveUser({})
            setLoading(false);
            displaySuccess(`User removed succesfully`);
            setSentinel(Symbol());
        } else {
            const response = await request.json();
            displayErrors(response);
        }

        setLoading(false);

    }

    return (
        <div className="ui container" style={{ marginTop: '2em' }}>
            <PageHeader
                title="Users"
                icon="users"
            />
            <Table basic='very' style={{ marginTop: '4em' }}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Active</Table.HeaderCell>
                        <Table.HeaderCell>Billable</Table.HeaderCell>
                        <Table.HeaderCell>Email address</Table.HeaderCell>
                        <Table.HeaderCell textAlign="right">View</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {users.status === 'resolved' && users.value.map((user, index) => {
                        return (
                            <Table.Row
                                key={index}
                            >
                                <Table.Cell collapsing>
                                    <Label
                                        color={user.active == 1 ? "green" : "black"}
                                    >
                                        <Icon style={{ margin: "0px" }} name={user.active == 1 ? "check" : "remove"} />
                                    </Label>
                                </Table.Cell>
                                <Table.Cell collapsing>
                                    <Label
                                        color={user.active == 1 ? "green" : "black"}
                                    >
                                        <Icon style={{ margin: "0px" }} name={user.active == 1 ? "check" : "remove"} />
                                    </Label>
                                </Table.Cell>
                                <Table.Cell>{user.emailAddress}</Table.Cell>
                                <Table.Cell textAlign="right">
                                    <Dropdown
                                        icon='cog'
                                        basic
                                        direction="left"
                                        button
                                        className='icon'
                                        floating
                                    >
                                        <Dropdown.Menu>
                                            <Dropdown.Item onClick={() => navigate(`/users/${user.id}`)}>
                                                <Icon name="edit" />Edit
                                            </Dropdown.Item>
                                            <Dropdown.Item
                                                onClick={e => {
                                                    setOpenModal(true)
                                                    setRemoveUser({
                                                        email: user.emailAddress,
                                                        id: user.id,
                                                    })
                                                }}
                                            >
                                                <Icon name="trash" />
                                                Delete
                                            </Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </Table.Cell>
                            </Table.Row>
                        )
                    })}
                    {users.status !== 'resolved' && (
                        <Table.Row>
                            <Table.Cell>
                                Loading
                            </Table.Cell>
                        </Table.Row>
                    )}
                </Table.Body>
            </Table>
            <SemanticModal
                open={openModal}
                title={"Are you sure?"}
                description={`Are you sure you want to remove user ${removeUser.email}`}
            >
                <Button
                    negative
                    onClick={() => setOpenModal(false)}
                >
                    Cancel
                </Button>
                <Button
                    positive
                    onClick={() => {
                        setOpenModal(false);
                        handleRemoveSelectedUser();
                    }}
                >
                    <Icon name='trash' />
                    Remove
                </Button>

            </SemanticModal>

        </div>
    )
}

/**
 * ((Boolean -> *), (PromiseState (Iterable User) -> *)) -> Promise Void
 * @param setLoading Boolean -> *
 * @param setUsers PromiseState (Iterable User) -> *
 * @return Promise Void
 */
const fetchUsers = async (setLoading, setUsers) => {
    try {

        setLoading(true);
        setUsers({ status: 'pending' })

        const response = await usersDataRequest({
            order: [
                ['emailAddress', 1]
            ]
        });

        if (!response.ok) {

            const contentType = response.headers.get('Content-Type');

            if (isJsonMediaType(contentType)) {

                const responseBody = await response.json();

                throw new Error(responseBody.error?.message ?? response.statusText);

            }

            throw new Error(await response.text());

        }

        const users = await response.json();

        setUsers({
            status: 'resolved',
            value:  users
        })

    } finally {
        setLoading(false);
    }
};

/**
 * String -> Boolean
 * @param value String
 * @return Boolean
 */
const isJsonMediaType = value => /^application\/json(;.*)?$/i.test(value);
