/* eslint-disable react-hooks/rules-of-hooks */

import { constant as K }    from 'lodash'
import { identity as I }    from 'lodash'
import { Pagination }       from 'semantic-ui-react'
import { useEffect }        from 'react'
import { useState }         from 'react'

// export default usePagination

/**
 * React.Component -> Object * -> React.Element
 * @param Component React.Component
 * @param properties Object *
 * @return React.Element
 */
const createElement = Component => properties => {
    return <Component { ... properties } />
}

/**
 * (B -> A -> B) -> B -> Foldable A -> B
 * @param f B -> A -> B
 * @param initialValue B
 * @param structure Foldable A
 * @return B
 */
const foldLeft = f => initialValue => structure => {

    let accumulator = initialValue

    for (const value of structure)
        accumulator = f (accumulator) (value)

    return accumulator

}

/**
 * S -> S -> S
 * S = Semigroup
 * @param x S
 * @param y S
 * @return S
 */
const mergeLeft = x => y => ({ ... y, ... x })

/**
 * (A -> B) -> (B -> C) -> A -> C
 * @param f A -> B
 * @param g B -> C
 * @param x A
 * @return C
 */
const Q = f => g => x => g (f (x))

/**
 * (A -> B -> C) -> (A -> B) -> A -> C
 * @param f A -> B -> C
 * @param g A -> B
 * @param x A
 * @return C
 */
const S = f => g => x => f (x) (g (x))

/**
 * (Parameters A -> Promise (Null | Resultset B)) -> Dependencies A -> Pagination B
 * Dependencies A = Tuple N A
 * Pagination A = {
 *  count:          PromiseState Integer
 *  empty:          Boolean
 *  Pagination:     React.Component
 *  pageIndex:      Integer
 *  pageSize:       Integer
 *  resultset:      PromiseState (Resultset A)
 * }
 * Parameters A = {
 *  dependencies:   Dependencies A
 *  pageIndex:      Integer
 *  pageSize:       Integer
 * }
 * PromiseState A = { status: 'pending' } | { error: Error, status: 'rejected' } | { status: 'resolved', value: A }
 * Resultset A = {
 *  count: Integer
 *  items: A
 * }
 * @param f Parameters A -> Promise (Null | Resultset B)
 * @param dependencies Dependencies A
 * @return Pagination B
 */
const usePagination = f => dependencies => {

    const [count, setCount]         = useState (K ({ status: 'pending' }))
    const [pageIndex, setPageIndex] = useState (K (0))
    const [pageSize, setPageSize]   = useState (K (10))
    const [resultset, setResultset] = useState (K ({ status: 'pending' }))

    const onPageChange = tap (async () => {

        void setCount ({ status: 'pending' })
        void setResultset ({ status: 'pending' })

        const resultset = await f ({
            dependencies,
            pageIndex,
            pageSize
        })

        if (resultset == null)
            return

        void setCount ({ status: 'resolved', value: resultset.count })
        void setResultset ({ status: 'resolved', value: resultset.items })

    })

    void useEffect (onPageChange, [pageIndex, pageSize, ... dependencies])

    const empty = count.status === 'resolved' && count.value === 0

    return {

        count,
        empty,
        pageIndex,
        pageSize,
        resultset,

        Pagination: pipe ([

            mergeLeft ({

                activePage: pageIndex + 1,
                totalPages: Math.ceil ((count.value ?? 0) / pageSize),

                onPageChange: (event, { activePage }) => {
                    void setPageIndex (activePage - 1)
                }

            }),

            createElement (Pagination)

        ])

    }

}

/**
 * Foldable (A -> A) -> A -> A
 * @param functions Foldable (A -> A)
 * @return A -> A
 */
const pipe = foldLeft (Q) (I)

/**
 * (A -> *) -> A -> A
 * @param f A -> *
 * @param value A
 * @return A
 */
const tap = S (K)

export default usePagination
