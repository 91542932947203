import { Server } from "../Constants/config";

export { attemptMyApiRequest }

const isJsonMediaType = value => /^application\/json(;.*)?$/i.test(value)

let accessTokenPromise = null

async function attemptMyApiRequest(resource, init) {

    const accessToken = sessionStorage.getItem('token');

    init = {

        ...init,

        headers: {

            ...init?.headers,

            'Authorization': `Bearer ${accessToken}`

        }

    }

    const response = await fetch(resource, init)

    const contentType = response.headers.get('Content-Type')

    if (response.status === 401 && isJsonMediaType(contentType)) {

        const responseBody = await response.json()

        if (responseBody.error?.code !== 'error-code-b50e') {
            // console.log(responseBody)
            throw new ApiError(responseBody.error?.message ?? 'Bad Request')
        }

        const accessToken = await refreshAccessTokenSharedPromise()

        sessionStorage.setItem('token', accessToken);

        return await attemptMyApiRequest(resource, init)

    }

    return response

}

async function refreshAccessToken() {

    const url = new URL(`/authentication/4.0/access-token`, Server.backendURL);

    const response = await fetch(url, {
        method: 'POST',
        credentials: 'include'
    })

    if (!response.ok) {

        const contentType = response.headers.get('Content-Type')

        if (isJsonMediaType(contentType)) {

            const responseBody = await response.json()

            // console.log(responseBody)
            throw new ApiError(responseBody.error?.message ?? 'Bad Request')

        } else {
            // console.log(await response.text())
            throw new ApiError('Bad Request')
        }

    }

    const responseBody = await response.json()

    return responseBody.accessToken

}

async function refreshAccessTokenSharedPromise() {

    if (accessTokenPromise != null)
        return accessTokenPromise

    try {

        accessTokenPromise = refreshAccessToken()

        return await accessTokenPromise

    } finally {
        accessTokenPromise = null
    }

}

class ApiError extends Error {

    constructor(...arguments$) {

        super(...arguments$)

        // https://v8.dev/docs/stack-trace-api#stack-trace-collection-for-custom-exceptions
        if (Error.captureStackTrace instanceof Function)
            Error.captureStackTrace(this, this.constructor)

    }

}

export { ApiError }
