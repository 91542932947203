import { useState, useEffect, createContext } from 'react';
import { userDataRequest, getCurrentSessionNetwork } from '../api/backend';

export const UserContext = createContext(null);

export const UserProvider = (properties) => {

    const accessToken = sessionStorage.getItem('token');

    const [sentinel, setSentinel] = useState()
    const [data, setData] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {

        setLoading(true);

        if (typeof (accessToken) == 'undefined' || accessToken == null) {
            setData(null);
            setLoading(false);
            return;
        }

        const fetchData = async () => {

            let userData = {}
            const requestSessionUserData = await userDataRequest();

            if ([200, 201, 204].includes(requestSessionUserData.status)) {
                userData = await requestSessionUserData.json();
            }

            // if session user is not admin, check if user's network has access to claimsPayableProcedure page.
            if (!userData.admin || userData.admin == false) {
                const requestSessionNetworkData = await getCurrentSessionNetwork({
                    projection: [
                        'claimsPayableProcedure',
                    ]
                });
                if ([200, 201, 204].includes(requestSessionNetworkData.status)) {
                    const networkData = await requestSessionNetworkData.json();
                    userData['claimsPayableProcedure'] = networkData['claimsPayableProcedure'];
                } else {
                    userData['claimsPayableProcedure'] = false;
                }

            }

            setData(userData);

            setLoading(false);

        }

        fetchData();

    }, [sentinel]);

    return (
        <UserContext.Provider value={{ data, setSentinel, loading, setLoading }}>
            {properties.children}
        </UserContext.Provider>
    );
}
