import DOMPurify from 'dompurify';
import PropTypes from 'prop-types';

export default SanitizedHtml

SanitizedHtml.propTypes = {
    children: PropTypes.string.isRequired
}

/**
 * Object * -> React.Element
 * @param properties Object *
 * @return React.Element
 */
function SanitizedHtml (properties) {

    const { children } = properties

    return <div dangerouslySetInnerHTML={ {
        __html: DOMPurify.sanitize (children, {
            USE_PROFILES: {
                html: true
            }
        })
    } } />

}
