import { Grid, Form, Button, Dropdown, Icon, Segment, Input, Checkbox } from 'semantic-ui-react'
import { useRef } from 'react';
import { useState, useEffect } from "react";
import PageHeader from "../Components/pageHeader";
import TableContinents from "../Components/tableContinents";
import { Errors } from '../Constants/errors';
import { networkInterestRatesDataRequest, networkCertificatePriceDataRequest, networkAffiliationsCurrenciesDataRequest, networkAffiliationsMinimumPremiumsDataRequest, currenciesDataRequest, networkAffiliationsDataRequest, continentsDataRequest, merchandiseTypesDataRequest, editNetworksDataRequest, createNetworksDataRequest } from '../api/backend';
import { useParams } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { displaySuccess, displayErrors } from '../Components/displayNotifications';
import Dropzone from '../Components/dropzone';

export default function EditNetworkAffiliation(properties) {

    const { title = "Network Affiliation" } = properties;
    const { affiliationId } = useParams();

    const [networkCurrencies, setNetworkCurrencies] = useState([]);
    const [optionsCurrencies, setOptionsCurrencies] = useState([]);
    const [optionsContinents, setOptionsContinents] = useState([]);
    const [optionsMerchandiseTypes, setOptionsMerchandiseTypes] = useState([]);
    const [selectedCurrencies, setSelectedCurrencies] = useState([])
    const [minimumPremium, setminimumPremium] = useState([])
    const [certificatePrices, setCertificatePrices] = useState([])
    const [currencies, setCurrencies] = useState([])
    const [homepageContent, setHomepageContent] = useState('');
    const [networkInterestRates, setNetworkInterestRates] = useState([]);
    const contractConditionsReference = useRef();

    const [formData, setFormData] = useState({})
    const [invalid, setInvalid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sentinel, setSentinel] = useState('');

    // ================================================= Retrieve data required for the select options
    useEffect(() => {

        const fetchData = async () => {

            setLoading(true);

            const requestCurrencies = await currenciesDataRequest();
            const requestContinents = await continentsDataRequest();
            const requestMerchandiseTypes = await merchandiseTypesDataRequest();
            const requestNetworkCurrencies = await networkAffiliationsCurrenciesDataRequest(affiliationId);
            const requestNetworkMinimumPremium = await networkAffiliationsMinimumPremiumsDataRequest(affiliationId);
            const requestNetworkCertificatePrice = await networkCertificatePriceDataRequest(affiliationId);

            if ([200, 201, 204].includes(requestCurrencies.status)) {
                const currencies = await requestCurrencies.json();
                setOptionsCurrencies(
                    currencies.map((currency, index) => {
                        return {
                            key: index,
                            text: currency.name,
                            code: currency.code,
                            value: currency.id
                        }
                    })
                )
            }

            if ([200, 201, 204].includes(requestContinents.status)) {
                setOptionsContinents(await requestContinents.json());
            }

            if ([200, 201, 204].includes(requestMerchandiseTypes.status)) {
                setOptionsMerchandiseTypes(await requestMerchandiseTypes.json());
            }

            if ([200, 201, 204].includes(requestNetworkCurrencies.status)) {
                const currencies = await requestNetworkCurrencies.json();

                setNetworkCurrencies(currencies);

                setSelectedCurrencies(currencies.map((currency, index) => {
                    return currency.id
                }))
            }

            if ([200, 201, 204].includes(requestNetworkMinimumPremium.status)) {
                setminimumPremium(await requestNetworkMinimumPremium.json());
            }

            if ([200, 201, 204].includes(requestNetworkCertificatePrice.status)) {
                setCertificatePrices(await requestNetworkCertificatePrice.json());
            }

            setLoading(false);
        }

        fetchData();

    }, [])

    useEffect(() => {

        const fetchData = async () => {

            setLoading(true);

            const requestNetworkInterestRates = await networkInterestRatesDataRequest(affiliationId);
            if ([200, 201, 204].includes(requestNetworkInterestRates.status)) {
                setNetworkInterestRates(await requestNetworkInterestRates.json())
            }

            setLoading(false);
        }

        fetchData();

    }, [sentinel])

    // ================================================= Retrieve affiliation data for editing
    useEffect(() => {

        // Only when editing an existing affiliation
        if (!affiliationId) return;

        const fetchData = async () => {

            setLoading(true);

            const request = await networkAffiliationsDataRequest({

                limit: 1,

                filter: {
                    id: affiliationId
                }

            });

            if ([200, 201, 204].includes(request.status)) {
                const networkAffiliationData = await request.json()
                setFormData(networkAffiliationData[0]);

                setLoading(false);
            }

            setLoading(false);
        }

        fetchData();
    }, [])


    const changeFormData = (e) => {

        setFormData({
            ...formData, [`${e.target.name}`]: e.target.value
        })

    }

    const hanldeSubmitFormNetwork = async (event) => {
        event.preventDefault();
        setLoading(true);

        if (!event.target.checkValidity()) {
            setInvalid(true);
            setLoading(false);
            return null;
        }

        const formData = new FormData(event.target);
        const request = affiliationId ? await editNetworksDataRequest(formData, affiliationId) : await createNetworksDataRequest(formData);

        if ([200, 201, 204].includes(request.status)) {
            displaySuccess(`Network Affiliation ${affiliationId ? 'updated' : 'created'} succesfully`);
            setLoading(false);
            setSentinel(Symbol());
        } else {
            const response = await request.json();
            displayErrors(response);
        }

        setLoading(false);

    }

    return (
        <Form
            onSubmit={hanldeSubmitFormNetwork}
            noValidate
        >
            <div className="ui container" style={{ marginTop: '2em' }}>
                <PageHeader
                    title={title}
                    icon={"user"}
                />
            </div>
            <section style={{
                marginTop: '2em',
                background: '#E6F4FA',
                padding: '20px 0px'
            }}>
                <div
                    className="ui container"
                >
                    <div style={{ marginTop: '2em' }}>

                        <Grid>
                            <Grid.Row columns={2} stretched>
                                <Grid.Column width={10}>
                                    <Form.Field {... {

                                        control:    Input,
                                        label:      'Network name',
                                        name:       'name',
                                        onChange:   changeFormData,
                                        required:   true,
                                        value:      formData.name,

                                        error: invalid && (formData?.name?.length ?? 0) === 0 && {
                                            content:    Errors.required,
                                            pointing:   'below'
                                        }

                                    }} />
                                    <Form.Field
                                        // icon="user"
                                        name="certificateTitle"
                                        value={formData.certificateTitle || ''}
                                        onChange={e => changeFormData(e)}
                                        control={Input}
                                        // iconPosition={'left'}
                                        label={"Certificate Title"}
                                        required
                                        error={invalid && !formData?.certificateTitle ? {
                                            content: Errors.required,
                                            pointing: 'below',
                                        } : false}
                                    />
                                    <Form.Field
                                        required
                                        error={invalid && !formData?.certificateTitle ? {
                                            content: Errors.required,
                                            pointing: 'below',
                                        } : false}
                                    >
                                        <label>Currencies</label>
                                        <Dropdown
                                            fluid
                                            multiple
                                            search
                                            selection
                                            name="currencies"
                                            options={optionsCurrencies}
                                            value={selectedCurrencies || []}
                                            onChange={(e, data) => setSelectedCurrencies(data.value)}
                                            required
                                            error={invalid && !formData?.certificateTitle ? {
                                                content: Errors.required,
                                                pointing: 'below',
                                            } : false}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={6} stretched>
                                    <label style={{ flexGrow: 0 }}><b>Logo</b></label>
                                    <Dropzone
                                        currentFilePath={formData.logoFilepath}
                                    />
                                </Grid.Column>
                            </Grid.Row>

                            {selectedCurrencies.map((value, index) => {

                                const currency = networkCurrencies.find(currency => {
                                    return currency.id === value
                                });

                                const icon = optionsCurrencies.find(currency => currency.value == value).code;
                                const initialPremium = minimumPremium.find(premium => premium.currency_id == value) || 0;
                                const initialCertPrice = certificatePrices.find(price => price.currency_id == value) || 0;

                                return (
                                    <Grid.Row columns={3} key={index}>
                                        <Grid.Column
                                            width={4}
                                        >
                                            <Form.Field>
                                                <label>Currency</label>
                                                <Segment
                                                    style={{
                                                        padding: "0.58em 1em",
                                                        marginTop: "0em"
                                                    }}
                                                >
                                                    {icon}
                                                </Segment>
                                            </Form.Field>
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <Form.Field
                                                control={Input}
                                                defaultValue={currency?.maximumContractValue ?? 1e6}
                                                label="Maximum contract value"
                                                name={`currencies[${index}][maximumContractValue]`}
                                                required
                                                error={invalid && !formData?.maximumContractValue && {
                                                    content:    Errors.required,
                                                    pointing:   'below'
                                                }}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <Form.Field
                                                name={`currencies[${index}][minimumPremium]`}
                                                defaultValue={initialPremium?.value}
                                                control={Input}
                                                label={"Minimum premium"}
                                                required
                                                error={invalid && !formData?.minimumPremium ? {
                                                    content: Errors.required,
                                                    pointing: 'below',
                                                } : false}
                                            />
                                        </Grid.Column>
                                        <Grid.Column width={4}>
                                            <Form.Field
                                                name={`currencies[${index}][certificatePrice]`}
                                                defaultValue={initialCertPrice?.value}
                                                control={Input}
                                                label={"Certificate price"}
                                                required
                                                error={invalid && !formData?.certificatePrice ? {
                                                    content: Errors.required,
                                                    pointing: 'below',
                                                } : false}
                                            />
                                        </Grid.Column>
                                        <input
                                            type="hidden"
                                            value={value}
                                            name={`currencies[${index}][currencyId]`}
                                        />
                                    </Grid.Row>
                                )
                            })}

                            <Grid.Row columns={2} stretched>
                                <Grid.Column width={6}>
                                    <Form.Field
                                        // icon="user"
                                        name="insuranceNumber"
                                        value={formData.insuranceNumber || ''}
                                        onChange={e => changeFormData(e)}
                                        control={Input}
                                        // iconPosition={'left'}
                                        label={"Insurance number"}
                                        required
                                        error={invalid && !formData?.insuranceNumber ? {
                                            content: Errors.required,
                                            pointing: 'below',
                                        } : false}
                                    />

                                </Grid.Column>
                                <Grid.Column>
                                        <label><b>Acces to Claims Payable Procedure</b></label>
                                        <Checkbox
                                            toggle
                                            checked={formData.claimsPayableProcedure || false}
                                            onChange={(e, data) => {
                                                setFormData({
                                                    ...formData, ["claimsPayableProcedure"]: data.checked ? 1 : 0
                                                })
                                            }}
                                        />
                                        <input
                                            type="hidden"
                                            name="claimsPayableProcedure"
                                            value={formData.claimsPayableProcedure || 0}
                                        />
                                    </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div >
                </div >
            </section>
            <div
                className="ui container"
                style={{
                    marginTop: '2em',
                    paddingBottom: '4em'
                }}
            >
                <b style={{ display: 'block', marginBottom: '1.5em' }}>
                    Contract conditions
                </b>
                <CKEditor {... {
                    data:   formData.contractConditions,
                    editor: ClassicEditor,
                    onChange: (event, editor) => {
                        const value = editor.getData();
                        contractConditionsReference.current.value = value;
                    }
                }} />
                <input {... {
                    name:   'contractConditions',
                    ref:    contractConditionsReference,
                    type:   'hidden'
                }} />

                <b style={{ marginBottom: "1.5rem", display: "block" }}>Homepage content</b>
                <CKEditor
                    editor={ClassicEditor}
                    data={formData.homePageContent || ""}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        setHomepageContent(data);
                    }}
                />
            </div>
            <input name={"homePageContent"} type="hidden" value={homepageContent} />
            <div
                className="ui container"
                style={{
                    paddingBottom: '4em'
                }}
            >
                {optionsMerchandiseTypes.map((type, index) => {
                    return (
                        <div key={index}>
                            <b>{type.value}</b>
                            <TableContinents
                                continents={optionsContinents}
                                merchandiseType={type.id}
                                networkInterestRates={networkInterestRates}
                            />
                        </div>
                    )
                })}

                <Button color="blue" icon labelPosition='left' disabled={loading}>
                    <Icon name='save' />
                    Save Changes
                </Button>
            </div >
            { affiliationId != null && (<input type="hidden" name="_method" value="PATCH" />) }
        </Form>
    )
}
