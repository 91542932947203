import { useState, useEffect } from "react";
import PageHeader from "../Components/pageHeader";
import { Table, Button, Icon, Dropdown, Grid } from 'semantic-ui-react'
import SemanticModal from "../Components/modal";
import { exemptCountriesDataRequest, addExemptCountryRequest, removeExemptCountryRequest } from "../api/backend";
import countryOptions from '../Constants/countries-ISO3166-1.json';
import { displayErrors, displaySuccess } from '../Components/displayNotifications';
import usePagination from '../Components/usePagination.jsx';

export default function ExcludeCountries() {

    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [removeCountry, setRemoveCountry] = useState({})
    const [selectedCountry, setSelectedCountry] = useState('');
    const [sentinel, setSentinel] = useState(false);

    const countries = usePagination(fetchExemptCountries)([sentinel, setLoading]);

    const handleAddSelectedCountry = async () => {

        setLoading(true);

        const request = await addExemptCountryRequest(selectedCountry);

        if ([200, 201, 204].includes(request.status)) {
            setSentinel(Symbol());
            displaySuccess(`Country added succesfully`);
            setSelectedCountry('');
            setLoading(false);
        } else {
            const response = await request.json();
            displayErrors(response);
        }

        setLoading(false);

    }

    const handleRemoveSelectedCountry = async (countryToRemove) => {

        setLoading(true);

        const request = await removeExemptCountryRequest(countryToRemove);

        if ([200, 201, 204].includes(request.status)) {
            setSentinel(Symbol());
            setRemoveCountry({})
            displaySuccess(`Country removed succesfully`);
            setLoading(false);
        } else {
            const response = await request.json();
            displayErrors(response);
        }

        setLoading(false);

    }

    return (
        <div className="ui container" style={{ marginTop: '2em' }}>
            <PageHeader
                title={"Exclude countries"}
                icon={"file"}
            />
            <Grid style={{ marginTop: "2em" }}>
                <Grid.Row columns={4}>
                    <Grid.Column stretched>
                        <Dropdown
                            placeholder='Search Country'
                            search
                            selection
                            options={countryOptions}
                            value={selectedCountry}
                            onChange={(event, data) => setSelectedCountry(data.value)}
                            clearable
                        />
                    </Grid.Column>
                    <Grid.Column>
                        {selectedCountry &&
                            <Button
                                color="green"
                                icon
                                labelPosition='left'
                                onClick={e => handleAddSelectedCountry()}
                            >
                                <Icon name='plus' />
                                Add selected country
                            </Button>
                        }
                    </Grid.Column>
                </Grid.Row>
            </Grid>
            <div style={{ marginTop: '2em' }}>
                <Table basic='very'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Country</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right'>Delete</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {countries.resultset.status === 'resolved' && countries.resultset.value.map((country, index) => {

                            const countryName = countryOptions.find(countryOption => countryOption.key == country.countryCode).text;

                            return (
                                <Table.Row
                                    key={index}
                                >
                                    <Table.Cell>{countryName}</Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        <Button
                                            basic
                                            icon='trash'
                                            onClick={() => {
                                                setOpenModal(true)
                                                setRemoveCountry({
                                                    "countryCode": country.countryCode,
                                                    "name": countryName
                                                })
                                            }}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                        {countries.resultset.status !== 'resolved' && (
                            <Table.Row>
                                <Table.Cell>
                                    Loading
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </div>
            <SemanticModal
                open={openModal}
                title={"Are you sure?"}
                description={`Are you sure you want to remove ${removeCountry.name} from excluded countries?`}
            >
                <Button
                    onClick={() => setOpenModal(false)}
                >
                    Cancel
                </Button>
                <Button
                    color={"green"}
                    onClick={() => {
                        handleRemoveSelectedCountry(removeCountry.countryCode || 0);
                        setOpenModal(false);
                    }}
                >
                    <Icon name='trash' />
                    Remove
                </Button>
            </SemanticModal>

            {countries.count.status === 'resolved' && countries.count.value > countries.pageSize && (
                <Grid centered style={{ marginTop: '2em' }}>
                    <countries.Pagination {... {
                        boundaryRange:  0,
                        ellipsisItem:   null,
                        firstItem:      null,
                        lastItem:       null,
                        siblingRange:   1
                    }} />
                </Grid>
            )}

        </div>
    )
}

/**
 * usePagination.Parameters (Symbol, Boolean -> *) -> Promise Void
 * @param parameters usePagination.Parameters (Symbol, Boolean -> *)
 * @return Promise Void
 */
const fetchExemptCountries = async parameters => {

    const { dependencies }  = parameters;
    const { pageIndex }     = parameters;
    const { pageSize }      = parameters;

    const setLoading = dependencies[1];

    try {

        setLoading(true);

        const response = await exemptCountriesDataRequest({
            limit:  pageSize,
            skip:   pageIndex * pageSize
        });

        if (!response.ok) {

            const contentType = response.headers.get('Content-Type');

            if (isJsonMediaType(contentType)) {

                const responseBody = await response.json();

                throw new Error(responseBody.error?.message ?? response.statusText);

            }

            throw new Error(await response.text());

        }

        let count;

        count = response;
        count = count.headers;
        count = count.get('Content-Range');
        count = count.match(/^items\s+(\d+)$/);
        count = count[1];

        const items = await response.json();

        return { count, items };

    } finally {
        setLoading(false);
    }

};

/**
 * String -> Boolean
 * @param value String
 * @return Boolean
 */
const isJsonMediaType = value => /^application\/json(;.*)?$/i.test(value);
