import { useState, Fragment } from 'react';
import { useDropzone } from 'react-dropzone'
import { Icon, Label } from 'semantic-ui-react';
import { Server } from '../Constants/config';

// import mySettings             from '../settings.js';

export default function Dropzone(properties) {

    const { currentFilePath = '' } = properties;

    const [files, setFiles] = useState([]);

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        acceptedFiles
    } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {

            setFiles(acceptedFiles.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));

            const inputProperties = getInputProps();

            const inputElement = inputProperties.ref.current;

            if (inputElement == null)
                return;

            const transfer = new DataTransfer;

            acceptedFiles.forEach(file => transfer.items.add (file));

            inputElement.files = transfer.files

        }
    });

    // const myDropAccepted = async (event) => {
    //     setFileError(0);
    // }

    // const myDropRejected = async (event) => {
    //     setFileError(1);
    // }

    let previewImage = false;

    if (files.length > 0) {
        previewImage = files[0].preview;
    } else if (currentFilePath) {
        previewImage = Server.storageUrl + currentFilePath;
    }

    return (
        <div {...getRootProps()}
            style={{
                backgroundColor: "#fff",
                border: "1px solid rgba(34,36,38,.15)",
                borderRadius: ".28571429rem"
            }}
        >
            <input
                {...getInputProps()}
                name="logo"
            />
            <div
                className={"dropzone" + (isDragActive ? ' active' : '')}
                style={{
                    backgroundImage: previewImage ? "url('" + previewImage + "')" : 'undefined',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%"
                }}
            >
                <Label color="blue" style={{ cursor: "pointer" }}>
                    <Icon name='upload' />
                    Upload Logo
                </Label>
            </div>
        </div>
    )
}
