import { UserProvider } from './Components/userContext';
import MaesstadRouter from './Components/maesstadRouter';

export default function App() {

    return (
        <UserProvider>
            <MaesstadRouter />
        </UserProvider>
    );
}