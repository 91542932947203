import PageHeader from "../Components/pageHeader";
import { Button, Input, Grid, Form } from 'semantic-ui-react'
import { userForgotPasswordRequest } from '../api/backend';
import { useState } from "react";
import { Errors } from "../Constants/errors";
import { displayErrors, displaySuccess } from '../Components/displayNotifications';

export default function ForgotPassword() {

    const [emailAddress, setEmailAddress] = useState('');
    const [invalid, setInvalid] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleSubmitLogin = async (e) => {

        e.preventDefault();
        setLoading(true);

        // let hasError = false;

        if (!e.target.checkValidity()) {
            setInvalid(true);
            setLoading(false);
            return null;
        }

        const request = await userForgotPasswordRequest({ emailAddress: emailAddress });

        if ([200, 201, 204].includes(request.status)) {
            displaySuccess("Instructions for account recovery have been sent to your E-mail address");
            setLoading(false);
        } else {
            const response = await request.json();
            displayErrors(response);
        }

        setLoading(false);

    }

    return (
        <div className="ui container" style={{ marginTop: '2em' }}>
            <PageHeader
                title={"Forgot password"}
                icon={"key"}
            />
            <div style={{ marginTop: '2em' }}>
                <Form
                    onSubmit={handleSubmitLogin}
                    noValidate
                >
                    <Grid>
                        <Grid.Row columns={3} stretched>
                            <Grid.Column>
                                <Form.Field
                                    icon={'mail'}
                                    control={Input}
                                    iconPosition={'left'}
                                    label={"E-mail address"}
                                    value={emailAddress}
                                    onChange={e => setEmailAddress(e.target.value)}
                                    required
                                    error={invalid && !emailAddress ? {
                                        content: Errors.email,
                                        pointing: 'below',
                                    } : false}
                                />
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={3} stretched>
                            <Grid.Column>
                                <Button
                                    color="orange"
                                    disabled={loading}
                                >
                                    Request new password
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={3} stretched>
                            <Grid.Column>
                                <a href="/sign-up">Sign up</a>
                                <a href="/">Back to login</a>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form>
            </div>
        </div>
    )
}