import { useState, useEffect } from 'react';
import SemanticModal from "../Components/modal";
import PageHeader from "../Components/pageHeader";
import { useNavigate } from 'react-router-dom'
import { Table, Dropdown, Icon, Button, Grid } from 'semantic-ui-react'
import { networkAffiliationsDataRequest } from "../api/backend"
import usePagination from '../Components/usePagination.jsx';

export default function NetworkAffiliations() {

    const [openModal, setOpenModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [removeAffiliation, setRemoveAffiliation] = useState({})
    const [selectedAffiliation, setSelectedAffiliation] = useState('');
    const [sentinel, setSentinel] = useState(false);

    const networkAffiliations = usePagination(fetchNetworks)([
        sentinel,
        setLoading
    ]);

    const navigate = useNavigate();

    // const handleRemoveSelectedAffiliation = async (countryToRemove) => {

    //     setLoading(true);

    //     const request = await removeExemptCountryRequest(countryToRemove);

    //     if ([200, 201, 204].includes(request.status)) {
    //         setSentinel(Symbol());
    //         setRemoveCountry({})
    //         setLoading(false);
    //     }

    //     setLoading(false);

    // }

    return (
        <div className="ui container" style={{ marginTop: '2em' }}>
            <PageHeader
                title={"Network Affiliations"}
                icon={"user"}
            />

            <Grid style={{ marginTop: "2em" }}>
                <Grid.Row>
                    <Grid.Column>
                        <Button
                            onClick={() => navigate(`/network-affiliations/create`)}
                            color="green"
                            icon
                            labelPosition='left'
                        >
                            <Icon name='plus' />
                            Create new Network Affiliation
                        </Button>
                    </Grid.Column>
                </Grid.Row>
            </Grid>

            <div style={{ marginTop: '2em' }}>
                <Table basic='very'>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>Network</Table.HeaderCell>
                            <Table.HeaderCell textAlign='right'>Edit</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {networkAffiliations.resultset.status === 'resolved' && networkAffiliations.resultset.value.map((affiliation, index) => {
                            return (
                                <Table.Row key={index}>
                                    <Table.Cell>{affiliation.name}</Table.Cell>
                                    <Table.Cell textAlign='right'>
                                        <Dropdown
                                            icon='cog'
                                            basic
                                            direction="left"
                                            button
                                            className='icon'
                                            floating
                                        >
                                            <Dropdown.Menu>
                                                <Dropdown.Item onClick={() => navigate(`/network-affiliations/${affiliation.id}`)}><Icon name="edit" />Edit</Dropdown.Item>
                                                <Dropdown.Item onClick={() => {
                                                    setOpenModal(true)
                                                    setRemoveAffiliation({
                                                        "countryCode": affiliation.id,
                                                        "name": affiliation.certificateTitle
                                                    })
                                                }}>
                                                    <Icon name="trash" />
                                                    Delete
                                                </Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Table.Cell>
                                </Table.Row>
                            )
                        })}
                        {networkAffiliations.resultset.status !== 'resolved' && (
                            <Table.Row>
                                <Table.Cell>
                                    Loading
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </Table.Body>
                </Table>
            </div >
            <SemanticModal
                open={openModal}
                title={"Are you sure?"}
                description={`Are you sure you want to remove ${removeAffiliation.name} ?`}
            >
                <Button
                    onClick={() => setOpenModal(false)}
                >
                    Cancel
                </Button>
                <Button
                    color="green"
                    onClick={() => {
                        // handleRemoveSelectedAffiliation(removeAffiliation)
                        setOpenModal(false);
                    }}
                >
                    <Icon name='trash' />
                    Remove
                </Button>

            </SemanticModal>

            {networkAffiliations.count.status === 'resolved' && networkAffiliations.count.value > networkAffiliations.pageSize && (
                <Grid centered style={{ marginTop: '2em' }}>
                    <networkAffiliations.Pagination {... {
                        boundaryRange:  0,
                        ellipsisItem:   null,
                        firstItem:      null,
                        lastItem:       null,
                        siblingRange:   1
                    }} />
                </Grid>
            )}

        </div >
    )
}

/**
 * Parameters (Symbol, Boolean -> *) -> Promise Void
 * @param parameters Parameters (Symbol, Boolean -> *)
 * @return Promise Void
 */
const fetchNetworks = async parameters => {

    const { dependencies }  = parameters;
    const { pageIndex }     = parameters;
    const { pageSize }      = parameters;

    const setLoading = dependencies[1];

    try {

        setLoading(true);

        const response = await networkAffiliationsDataRequest({
            limit:  pageSize,
            skip:   pageIndex * pageSize
        });

        if (!response.ok) {

            const contentType = response.headers.get('Content-Type');

            if (isJsonMediaType(contentType)) {

                const responseBody = await response.json();

                throw new Error(responseBody.error?.message ?? response.statusText);

            }

            throw new Error(await response.text());

        }

        let count;

        count = response;
        count = count.headers;
        count = count.get('Content-Range');
        count = count.match(/^items\s+(\d+)$/);
        count = count[1];

        const items = await response.json();

        return { count, items };

    } finally {
        setLoading(false);
    }

};

/**
 * String -> Boolean
 * @param value String
 * @return Boolean
 */
const isJsonMediaType = value => /^application\/json(;.*)?$/i.test(value);
