import Footer from "./footer";
import Navigation from "./navigation";
import { useContext } from 'react';
import { UserContext } from './userContext';

import Login from "../Pages/login";
import ExcludeCountries from '../Pages/exclude-countries';
import NetworkAffiliations from '../Pages/network-affiliations';
import EditNetworkAffiliation from '../Pages/edit-network-affiliation';
import CargoCertificates from '../Pages/cargo-certificates';
import EditCargoCertificates from '../Pages/edit-cargo-certificates';
import Users from '../Pages/users';
import EditUsers from '../Pages/edit-users';
import InterestRates from '../Pages/interest-rates';
import ClaimsPayableProcedure from '../Pages/claims-payable-procedure';
import ForgotPassword from '../Pages/forgot-password';
import AccountRecovery from "../Pages/account-recovery";
import HomeUsers from "../Pages/home-users";
import { ProtectedRoute } from './protectedRoute';
import { BrowserRouter as Router, Routes, Route, Navigate, Link, Outlet } from 'react-router-dom';
import useSession from './useSession';
import { ToastContainer } from 'react-toastify';

export default function MaesstadRouter() {

    const { session, setSession, clearSession } = useSession();

    const userContext = useContext(UserContext);
    const sessionUser = userContext.data;
    const loading = userContext.loading;

    const isAdmin = sessionUser && sessionUser?.admin;
    const isUser = sessionUser && sessionUser?.admin == false && sessionUser?.accountant == false
    const isAccountant = sessionUser && sessionUser?.admin == false && sessionUser?.accountant == true
    const claimsPayableProcedure = sessionUser && sessionUser?.admin == false && sessionUser?.claimsPayableProcedure == true;

    const RootPageswitch = () => {
        if (isAdmin) return <Navigate to={'/cargo-certificates'} replace />
        if (isUser || isAccountant) return <HomeUsers />;
        return <Navigate to={'/login'} replace />
    }

    if (loading) {
        return <h1>Loading</h1>
    }

    return (
        <Router>

            {sessionUser &&
                <Navigation clearSession={clearSession} />
            }

            <Routes>

                <Route path="login" element={
                    <Login setSession={setSession} />
                } />

                <Route path="/" element={
                    <RootPageswitch />
                } />

                <Route path="sign-up" element={
                    <EditUsers
                        title="Sign Up"
                        signUp={true}
                        isAdmin={false}
                    />
                } />

                <Route
                    path="forgot-password"
                    element={<ForgotPassword />}
                />

                <Route
                    path="account-recovery"
                    element={<AccountRecovery />}
                />

                {/* ======================================== MUST BE LOGGED IN */}
                <Route element={<ProtectedRoute isAllowed={!!sessionUser} />}>

                    <Route
                        path="cargo-certificates"
                        element={<CargoCertificates isAdmin={sessionUser?.admin} isAccountant={isAccountant} />}
                    />

                </Route>

                {/* ======================================== MUST BE LOGGED IN AS NORMAL USER */}
                <Route
                    element={<ProtectedRoute isAllowed={!!isUser && !isAccountant} />}
                >

                    <Route
                        path="cargo-certificates/:certificateId"
                        element={<EditCargoCertificates title={"Edit Certificate"} />}
                    />

                    <Route
                        path="cargo-certificates/create"
                        element={<EditCargoCertificates title={"Create Certificate"} />}
                    />

                </Route>

                {/* ======================================== MUST BE LOGGED IN AS NORMAL OR ACCOUNTANT USER */}
                <Route
                    element={<ProtectedRoute isAllowed={!!isUser || !!isAccountant} />}
                >

                    <Route
                        path="profile/:userId"
                        element={<EditUsers title={"Profile"} isAdmin={false} />}
                    />

                    <Route
                        path="interest-rates"
                        element={<InterestRates />}
                    />



                </Route>

                {/* ======================================== MUST BE LOGGED IN AS NORMAL OR ACCOUNTANT USER AND HAVE ACCESS TO CLAIM PAYABLE PROCEDURE*/}
                <Route
                    element={<ProtectedRoute isAllowed={!!isUser || !!isAccountant && claimsPayableProcedure} />}
                >
                    <Route
                        path="claims-payable-procedure"
                        element={<ClaimsPayableProcedure />}
                    />
                </Route>

                {/* ======================================== MUST BE LOGGED IN AS ADMIN */}
                <Route element={<ProtectedRoute isAllowed={!!isAdmin} />}>

                    <Route
                        path="exclude-countries"
                        element={<ExcludeCountries />}
                    />
                    <Route
                        path="network-affiliations"
                        element={<NetworkAffiliations />}
                    />
                    <Route
                        path="network-affiliations/:affiliationId"
                        element={<EditNetworkAffiliation title={"Edit Network Affiliation"} />}
                    />
                    <Route
                        path="network-affiliations/create"
                        element={<EditNetworkAffiliation title={"Create Network Affiliation"} />}
                    />
                    <Route
                        path="users"
                        element={<Users />}
                    />
                    <Route
                        path="users/:userId"
                        element={<EditUsers isAdmin={true} />}
                    />
                </Route>

            </Routes>
            <ToastContainer />
            <Footer />
        </Router>

    )
}