import { Header, Icon } from 'semantic-ui-react'

export default function PageHeader(properties) {

    const { title, icon } = properties;

    return (
        <Header as='h1'>
            <Icon circular inverted color='orange' size={"small"} name={icon} />
            <Header.Content>{title}</Header.Content>
        </Header>
    )
}