import { downloadCertificate, downloadBookingSlip, downloadCertificatesXLS } from "../api/backend";

export async function DownloadCertificate(contractId) {

    const requestDownloadCertificate = await downloadCertificate(contractId);

    if ([200, 201, 204].includes(requestDownloadCertificate.status)) {
        const file = await requestDownloadCertificate.blob()
        const url = URL.createObjectURL(file)
        const tab = window.open(url, '_blank')
        void tab.focus()
    }
  
}

export async function DownloadBookingSlip(contractId) {

    const requestDownloadBookingSlip = await downloadBookingSlip(contractId);

    if ([200, 201, 204].includes(requestDownloadBookingSlip.status)) {
        const file = await requestDownloadBookingSlip.blob()
        const url = URL.createObjectURL(file)
        const tab = window.open(url, '_blank')
        void tab.focus()
    }
  
}

export async function DownloadCertificatesXLS(filters) {

    const requestDownloadCertificatesXLS = await downloadCertificatesXLS(filters);

    if ([200, 201, 204].includes(requestDownloadCertificatesXLS.status)) {
        const file = await requestDownloadCertificatesXLS.blob()
        const url = URL.createObjectURL(file)
        const tab = window.open(url, '_blank')
        void tab.focus()
    }
  
}

