import { css }      from '@emotion/css';
import PageHeader from "../Components/pageHeader";
import { Loader, Image } from 'semantic-ui-react'
import { useState, useEffect } from "react";
import { displayErrors } from '../Components/displayNotifications.js';
import { getCurrentSessionNetwork } from '../api/backend.js';
import { networkInterestRatesDataRequest, merchandiseTypesDataRequest } from '../api/backend';
import SanitizedHtml from '../Components/SanitizedHtml.jsx';
import { Server } from '../Constants/config';

export default function HomeUsers() {

    const [loading, setLoading] = useState(false);
    const [network, setNetwork] = useState({ status: 'pending' });
    const [networkInterestRates, setNetworkInterestRates] = useState([]);
    const [optionsMerchandiseTypes, setOptionsMerchandiseTypes] = useState([]);

    useEffect(() => {

        const fetchData = async () => {

            setNetwork({ status: 'pending' });

            try {

                const response = await getCurrentSessionNetwork({
                    projection: [
                        'homePageContent',
                        'logoFilePath'
                    ]
                })

                if (!response.ok) {
                    const { message } = await response.json();
                    throw new Error(message);
                }

                const network = await response.json();

                setNetwork({ status: 'resolved', value: network });

            } catch (error) {
                setNetwork({ status: 'rejected', error });
                displayErrors([error]);
            }

        };

        fetchData();

    }, [setNetwork]);

    // ================================================= Retrieve data required for the select options
    useEffect(() => {

        const fetchData = async () => {

            setLoading(true);

            const requestMerchandiseTypes = await merchandiseTypesDataRequest();
            const requestNetworkInterestRates = await networkInterestRatesDataRequest(false, true);

            if ([200, 201, 204].includes(requestMerchandiseTypes.status)) {
                setOptionsMerchandiseTypes(await requestMerchandiseTypes.json());
            }

            if ([200, 201, 204].includes(requestNetworkInterestRates.status)) {
                setNetworkInterestRates(await requestNetworkInterestRates.json())
            }

            setLoading(false);
        }

        fetchData();

    }, [])

    return (
        <div className="ui container" style={{ marginTop: '2em' }}>

            <PageHeader
                title={"Home"}
                icon={"home"}
            />

            {network.status === 'resolved'
            ? <>
                <SanitizedHtml>
                    {network.value.homePageContent}
                </SanitizedHtml>
                {network.value.logoFilePath && <Image { ... {

                    src: Server.storageUrl + network.value.logoFilePath,

                    className: css ({
                        maxHeight:  'calc((225 / 14) * 1rem) !important',
                        maxWidth:   'calc((400 / 14) * 1rem) !important',
                        objectFit:  'contain'
                    })

                } } />}
            </>
            : <Loader active />}

        </div>
    )
}
