import { DateTime } from 'luxon';
import { Duration } from 'luxon';
import { Grid, Form, Button, Dropdown, Icon, Input } from 'semantic-ui-react'
import { useRef } from 'react';
import { useState, useEffect } from "react";
import PageHeader from "../Components/pageHeader";
import currencyIcons from "../Constants/currency-icons.json";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Errors } from '../Constants/errors';
import { useParams } from 'react-router-dom';
import { continentsDataRequest, merchandiseTypesDataRequest, createCargoCertificateRequest, cargoCertificatesDataRequest, editCargoCertificateRequest } from '../api/backend';
import { contractTypesDataRequest } from '../api/backend.js';
import { getCurrentSessionCurrencies } from '../api/backend.js';
import { displayErrors, displaySuccess } from '../Components/displayNotifications';
import { userDataRequest } from '../api/backend.js';
import Autocomplete from "react-google-autocomplete";
import Atlas from "../Constants/continents.json";

export default function EditCargoCertificates(properties) {

    const { title } = properties;
    const { certificateId } = useParams();

    const [destinationContinentName, setDestinationContinentName] = useState(() => '');
    const [minimumDepartureDate, setMinimumDepartureDate] = useState(new Date());
    const [optionsCurrencies, setOptionsCurrencies] = useState([]);
    const [optionsMerchandiseTypes, setOptionsMerchandiseTypes] = useState([]);
    const [continentsData, setContinentsData] = useState([]);
    const [placeFrom, setPlaceFrom] = useState();
    const [placeTo, setPlaceTo] = useState();
    const [sourceContinentName, setSourceContinentName] = useState(() => '');
    const destinationCityReference = useRef(null);
    const sourceCityReference = useRef(null);

    const [formData, setFormData] = useState({})
    const [invalid, setInvalid] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {

        const fetchData = async () => {
            try {

                const userResponse = await userDataRequest();

                if (!userResponse.ok) {
                    const { message } = await userResponse.json();
                    throw new Error(message);
                }

                const user = await userResponse.json();

                const contractTypesResponse = await contractTypesDataRequest({

                    limit: 1,

                    filter: {
                        id: user.contractExpirationType_id
                    },

                    projection: [
                        'expirationDate'
                    ]

                });

                if (!contractTypesResponse.ok) {
                    const { message } = await contractTypesResponse.json();
                    throw new Error(message);
                }

                const contractTypes = await contractTypesResponse.json();

                const contractType = contractTypes[0];
                const currentTime = DateTime.now();

                const duration = Duration.fromISO(contractType.expirationDate);

                let minimumDepartureDate;

                minimumDepartureDate = currentTime.minus(duration);
                minimumDepartureDate = minimumDepartureDate.startOf('day');

                setMinimumDepartureDate(minimumDepartureDate.toJSDate());

            } catch (error) {
                displayErrors([error]);
            }
        };

        fetchData();

    }, [setMinimumDepartureDate]);

    // ================================================= Retrieve data required for the select options
    useEffect(() => {

        const fetchData = async () => {

            setLoading(true);

            const requestMerchandiseTypes = await merchandiseTypesDataRequest();
            const requestCurrencies = await getCurrentSessionCurrencies({
                projection: [
                    'id',
                    'name'
                ]
            });
            const requestContinents = await continentsDataRequest();

            if ([200, 201, 204].includes(requestContinents.status)) {
                const continents = await requestContinents.json();
                setContinentsData(continents);
            }

            if ([200, 201, 204].includes(requestMerchandiseTypes.status)) {
                const merchandiseTypes = await requestMerchandiseTypes.json();
                setOptionsMerchandiseTypes(
                    merchandiseTypes.map((type, index) => {
                        return {
                            key: index,
                            text: type.value,
                            value: type.id
                        }
                    })
                )
            }

            if ([200, 201, 204].includes(requestCurrencies.status)) {
                const currencies = await requestCurrencies.json();
                setOptionsCurrencies(
                    currencies.map((currency, index) => {
                        return {
                            key: index,
                            text: currency.name,
                            value: currency.id
                        }
                    })
                )
            }

            setLoading(false);
        }

        fetchData();

    }, [])

    // ================================================= Retrieve certificate data for editing
    useEffect(() => {

        // Only when editing an existing certificate
        if (!certificateId) return;

        const fetchData = async () => {

            setLoading(true);

            const request = await cargoCertificatesDataRequest({ certificateId });

            if ([200, 201, 204].includes(request.status)) {
                const cargoCertificatesData = await request.json()
                setFormData(cargoCertificatesData[0]);
                setLoading(false);
            }

            setLoading(false);
        }

        fetchData();
    }, [])

    // ================================================= POST certificate data
    const handleSubmitCertificateForm = async (e) => {

        e.preventDefault();
        setLoading(true);

        // let hasError = false;

        if (!e.target.checkValidity()) {
            setInvalid(true);
            setLoading(false);
            return null;
        }

        const request = certificateId ? await editCargoCertificateRequest(formData, certificateId) : await createCargoCertificateRequest(formData);

        if ([200, 201, 204].includes(request.status)) {
            displaySuccess(`Certificate ${certificateId ? 'updated' : 'created'} succesfully`);
            setLoading(false);
        } else {
            const response = await request.json();
            displayErrors(response);
        }

        setLoading(false);

    }

    useEffect(() => {

        if (!placeFrom) {
            return;
        }

        if (placeFrom) {

            let cityName;

            for (const component of placeFrom.address_components) {
                if (component.types.includes('locality')) {
                    cityName = component.long_name;
                    break;
                }
            }

            if (cityName == null) {

                cityName = placeFrom.address_components.filter(component => {
                    return component.types.some(type => {

                        const types = [
                            'administrative_area_level_1',
                            'administrative_area_level_2'
                        ];

                        return types.includes(type);

                    });
                });

                cityName = cityName.map(component => component.long_name);

                cityName = cityName.join(', ');

            }

            for (const component of placeFrom.address_components) {
                if (component.types.includes('country')) {

                    const countryName = component.long_name;
                    const myISO3166Hyphen1Alpha2CountryCode = component.short_name;
                    const continentByCountry = Atlas.find(country => country.a2 == myISO3166Hyphen1Alpha2CountryCode).continent_name;
                    const continentIdByName = continentsData.find(continent => continent.name == continentByCountry).id;

                    setSourceContinentName(continentByCountry);

                    sourceCityReference.current.value = cityName;

                    setFormData({
                        ...formData,
                        sourceCity: cityName,
                        sourceCountry: countryName,
                        sourceContinentId: continentIdByName
                    })

                    break;
                }
            }
        }

    }, [ placeFrom ])

    useEffect(() => {

        if (!placeTo) {
            return;
        }

        if (placeTo) {

            let cityName;

            for (const component of placeTo.address_components) {
                if (component.types.includes('locality')) {
                    cityName = component.long_name;
                    break;
                }
            }

            if (cityName == null) {

                cityName = placeTo.address_components.filter(component => {
                    return component.types.some(type => {

                        const types = [
                            'administrative_area_level_1',
                            'administrative_area_level_2'
                        ];

                        return types.includes(type);

                    });
                });

                cityName = cityName.map(component => component.long_name);

                cityName = cityName.join(', ');

            }

            for (const component of placeTo.address_components) {
                if (component.types.includes('country')) {

                    const countryName = component.long_name;
                    const myISO3166Hyphen1Alpha2CountryCode = component.short_name;
                    const continentByCountry = Atlas.find(country => country.a2 == myISO3166Hyphen1Alpha2CountryCode).continent_name;
                    const continentIdByName = continentsData.find(continent => continent.name == continentByCountry).id;

                    destinationCityReference.current.value = cityName;

                    setDestinationContinentName(continentByCountry);

                    setFormData({
                        ...formData,
                        destinationCity: cityName,
                        destinationCountry: countryName,
                        destinationContinentId: continentIdByName
                    })

                    break;
                }
            }
        }

    }, [ placeTo ])

    const changeFormData = (e) => {

        setFormData({
            ...formData, [`${e.target.name}`]: e.target.value
        })

    }

    const initialDate = formData && formData.departureDate ? DateTime.fromSQL(formData?.departureDate).toJSDate() : '';

    return (
        <>
            <div className="ui container" style={{ marginTop: '2em' }}>
                <PageHeader
                    title={title}
                    icon={"file"}
                />
            </div>
            <Form
                onSubmit={handleSubmitCertificateForm}
                noValidate
            >
                <div className="ui container">
                    <div style={{ marginTop: '2em' }}>
                        <Grid>
                            <Grid.Row columns={3} stretched>
                                <Grid.Column width={8}>
                                    <Form.Field
                                        icon="user"
                                        name="nameAssured"
                                        value={formData.nameAssured || ''}
                                        onChange={e => changeFormData(e)}
                                        control={Input}
                                        iconPosition={'left'}
                                        label={"Name assured"}
                                        required
                                        error={invalid && !formData?.nameAssured ? {
                                            content: Errors.required,
                                            pointing: 'below',
                                        } : false}
                                    />
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Form.Field
                                        required
                                        error={invalid && !formData?.currencyId ? {
                                            content: Errors.required,
                                            pointing: 'below',
                                        } : false}
                                    >
                                        <label>Currency</label>
                                        <Dropdown
                                            fluid
                                            search
                                            selection
                                            name="currencyId"
                                            options={optionsCurrencies}
                                            value={formData.currencyId || ''}
                                            onChange={(e, data) => {
                                                setFormData({
                                                    ...formData, [`currencyId`]: data.value
                                                })
                                            }}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column width={4}>
                                    <Form.Field
                                        icon={formData.currencyId ? currencyIcons[formData.currencyId] : undefined}
                                        name="value"
                                        value={formData.value || ''}
                                        onChange={e => changeFormData(e)}
                                        control={Input}
                                        iconPosition={'left'}
                                        label={"Value of insured merchandise"}
                                        required
                                        error={invalid && !formData?.value ? {
                                            content: Errors.required,
                                            pointing: 'below',
                                        } : false}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                </div>

                <section style={{
                    marginTop: '2em',
                    background: '#E6F4FA',
                    padding: '20px 0px'
                }}>
                    <div
                        className="ui container"
                    >
                        <div style={{ marginTop: '2em' }}>
                            <Grid>
                                <Grid.Row columns={3} stretched>
                                    <Grid.Column>
                                        <Form.Field
                                            required
                                            error={invalid && !formData?.merchandiseTypeId ? {
                                                content: Errors.required,
                                                pointing: 'below',
                                            } : false}
                                        >
                                            <label>Type</label>
                                            <Dropdown
                                                fluid
                                                search
                                                selection
                                                name="merchandiseTypeId"
                                                options={optionsMerchandiseTypes}
                                                value={formData.merchandiseTypeId ?? ''}
                                                onChange={(e, data) => {
                                                    setFormData({
                                                        ...formData, [`merchandiseTypeId`]: data.value
                                                    })
                                                }}
                                            />
                                        </Form.Field>
                                    </Grid.Column>
                                    <Grid.Column stretched>
                                        <label
                                            style={{ flexGrow: 0 }}
                                            className="labelmargin"
                                        >
                                            <b>Description</b>
                                        </label>
                                        <textarea
                                            name="description"
                                            value={formData.description || ''}
                                            onChange={e => changeFormData(e)}
                                        />
                                    </Grid.Column>
                                    <Grid.Column stretched>
                                        <Form.Field
                                            icon="archive"
                                            name="containerIdentifier"
                                            value={formData.containerIdentifier || ''}
                                            onChange={e => changeFormData(e)}
                                            control={Input}
                                            iconPosition={'left'}
                                            label={"Container ID"}
                                            required
                                            error={invalid && !formData?.containerIdentifier ? {
                                                content: Errors.required,
                                                pointing: 'below',
                                            } : false}
                                        />

                                        <Form.Field
                                            icon="bookmark"
                                            name="reference"
                                            value={formData.reference || ''}
                                            onChange={e => changeFormData(e)}
                                            control={Input}
                                            iconPosition={'left'}
                                            label={"Reference"}
                                            required
                                            error={invalid && !formData?.reference ? {
                                                content: Errors.required,
                                                pointing: 'below',
                                            } : false}
                                        />
                                        {certificateId &&
                                            <Form.Field
                                                icon="file"
                                                name="invoiceIdentifier"
                                                value={certificateId || ''}
                                                onChange={e => changeFormData(e)}
                                                control={Input}
                                                iconPosition={'left'}
                                                label={"Invoice ID"}
                                                readOnly
                                            />
                                        }

                                        <Form.Field
                                            icon="truck"
                                            name="transportIdentifier"
                                            value={formData.transportIdentifier || ''}
                                            onChange={e => changeFormData(e)}
                                            control={Input}
                                            iconPosition={'left'}
                                            label={"Transport ID"}
                                            required
                                            error={invalid && !formData?.transportIdentifier ? {
                                                content: Errors.required,
                                                pointing: 'below',
                                            } : false}
                                        />
                                    </Grid.Column>
                                </Grid.Row>
                            </Grid>
                        </div >
                    </div >
                </section>
                <div className="ui container">
                    <div style={{ marginTop: '2em' }}>
                        <Grid>
                            <Grid.Row columns={3} stretched>
                                <Grid.Column className="datepicker-container">
                                    <label className="labelmargin"><b>Departure date</b></label>
                                    <SemanticDatepicker
                                        format="D/M/YYYY"
                                        minDate={minimumDepartureDate}
                                        value={initialDate ? initialDate : ''}
                                        onChange={(event, data) => {

                                            let departureDate;
                                            departureDate = data.value;
                                            departureDate = DateTime.fromJSDate(departureDate);
                                            departureDate = departureDate.toISODate();

                                            setFormData({
                                                ...formData,
                                                departureDate
                                            })

                                        }}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={3} stretched>
                                <Grid.Column stretched>
                                    <Form.Field
                                        required
                                    >
                                        <label>From</label>
                                        <Autocomplete
                                            language="en"
                                            options={{
                                                types: ['(cities)']
                                            }}
                                            defaultValue={formData.sourceCity || ''}
                                            apiKey={process.env.REACT_APP_PLACES_API_KEY}
                                            onPlaceSelected={(place) => {
                                                setPlaceFrom(place);
                                            }}
                                            ref={sourceCityReference}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column stretched>
                                    <Form.Field
                                        icon="globe"
                                        name="sourceCountry"
                                        value={formData.sourceCountry || ''}
                                        control={Input}
                                        iconPosition={'left'}
                                        label={"Country"}
                                        required
                                        readOnly
                                    />
                                </Grid.Column>
                                <Grid.Column stretched>
                                    <Form.Field
                                        icon="globe"
                                        value={sourceContinentName}
                                        control={Input}
                                        iconPosition={'left'}
                                        label={"Continent"}
                                        readOnly
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={3} stretched>
                                <Grid.Column stretched>
                                    <Form.Field
                                        required
                                    >
                                        <label>To</label>
                                        <Autocomplete
                                            language="en"
                                            options={{
                                                types: ['(cities)']
                                            }}
                                            defaultValue={formData.destinationCity || ''}
                                            apiKey={process.env.REACT_APP_PLACES_API_KEY}
                                            onPlaceSelected={(place) => {
                                                setPlaceTo(place);
                                            }}
                                            ref={destinationCityReference}
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column stretched>
                                    <Form.Field
                                        icon="globe"
                                        name="destinationCountry"
                                        value={formData.destinationCountry || ''}
                                        control={Input}
                                        iconPosition={'left'}
                                        label={"Country"}
                                        required
                                        readOnly
                                    />
                                </Grid.Column>
                                <Grid.Column stretched>
                                    <Form.Field
                                        icon="globe"
                                        value={destinationContinentName}
                                        control={Input}
                                        iconPosition={'left'}
                                        label={"Continent"}
                                        readOnly
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </div>
                    <div style={{ marginTop: '2em' }}>
                        <Button color="blue" icon labelPosition='left' disabled={loading}
                            onClick={e => setFormData({
                                ...formData, [`draft`]: true
                            })}
                        >
                            <Icon name='save' />
                            Save as draft
                        </Button>
                        <Button color="orange" icon labelPosition='left'
                            onClick={e => setFormData({
                                ...formData, [`draft`]: false
                            })}
                        >
                            <Icon name='save' />
                            Save as final
                        </Button>
                    </div>
                </div>
            </Form >
        </>
    )
}
